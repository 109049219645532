<template>
    <div class="track-versions-container-main">
		<div v-for="track in tracks">
			<div class="track-block">
					<div class="track-block_icon" v-on:click="playTrack(track)" :style="getImgUrl(album_covers_server, track.label_fk, track.album_num)"></div>
					<div class="track-block_title" v-on:click="playTrack(track)">
						<strong>{{ track.primary_title }} <span>{{ track.composers }}</span></strong>
						<p>{{ track.description }}</p>
						<p class="lab_alb_trck"><span>{{ $t('global.label') }}:</span> {{ track.label_fk.toUpperCase() }} <span>/</span> <span>{{ $t('global.album') }}:</span> {{ track.album_num.toUpperCase() }} <span>/</span> <span>{{ $t('global.track') }}:</span> {{ track.track_num }}</p>
					</div>
					<div class="track-block_versions">
						&nbsp;
					</div>
					<div class="track-block_stems">
						&nbsp;
					</div>
					
					
					<div class="track-block_time">{{ track.duration }}</div>
					
					
					<div class="track-block_download">
						<template v-if="$auth.check()">
							<i v-on:click="getFileLink(track.label_fk, track.album_num, track.track_num)" target="_blank" class="icon-btn-download"></i>
						</template>
						<template v-else>
							<a href="/login" style="opacity:1; position: relative" class="hint-top-right-s-small icon-btn-download" :data-hint="$t('global.login_to_download_a_track')"></a>
						</template>
					</div>
					
				</div>
			
		</div>
	</div>
</template>

<script>
	import Vue from 'vue';
    import axios from 'axios';
	import VueCookie from 'vue-cookie'
	Vue.use(VueCookie)
    export default {
		data() {
			return {
				l: null,
				a: null,
				t: null,
				tracks:null,
				album_covers_server: config.albumCoversServer,
				music_server: config.musicServer,
				opened: 0,
			};
		},
		mounted() {
			this.l = 0;
				this.a = 0;
				this.t = 0;
			this.opened = 0;
			
		},
		methods: {
			async show(l,a,t,type) {
				// console.log(this.opened + ' .... '+type);
				if (this.opened == type) {
					this.tracks = [];
					this.opened = 0;
					return;
				}
				this.opened = type;
				this.l = l;
				this.a = a;
				this.t = t;
				if (type == 1) {
						axios
							.get('track_versions?l='+this.l+'&a='+this.a+'&t='+this.t)
							.then(response => (this.tracks = response.data.data));
				}
				if (type == 2) {
						axios
							.get('track_stems?l='+this.l+'&a='+this.a+'&t='+this.t)
							.then(response => (this.tracks = response.data.data));
				}
				
			},
			
			playTrack(trackObj) { 
				let label = trackObj.label_fk;
				let album = trackObj.album_num;
				let track = trackObj.track_num;
				
				setPlayerData(trackObj, this.album_covers_server);
				
				$("#jquery_jplayer_obj").jPlayer("setMedia", {
					mp3: this.music_server + 'stream_music_file.php?label='+label.toLowerCase()+'&album='+album.toLowerCase()+'&track='+track,
				}).jPlayer("play");
				
				// console.log(label + ' '+ album + ' ' + track);
				//console.log(this.music_server + 'stream_music_file.php?label='+label.toLowerCase()+'&album='+album.toLowerCase()+'&track='+track);			
			},
			getImgUrl(server,label,album) {
				return "background-image:url('"+server+''+label+'/'+album+".jpg')";
			},
			getFileLink(label, album, track) {
				axios.get('/sfx_download', 
						{ 
							params: {
								l: label.toLowerCase(),
								a: album.toLowerCase(),
								t: track
							},
							headers: {"Authorization" : 'Bearer '+window.localStorage.getItem('auth_token_default')}, 
						}
					)
					.then(response => {
						var fileLink = document.createElement('a');
						fileLink.href = response.data.url;
						fileLink.setAttribute('download', response.data.fname+'.mp3');
						document.body.appendChild(fileLink);
						fileLink.click();
					  })
					  .catch(error => console.log(error))
			},
			getFavoriteStatus(trackObj) {
				let ftc = VueCookie.get('favorite_tracks');
				let favorite_tracks = [];
				if (ftc!==undefined && ftc!==null && ftc!=="") {
					favorite_tracks = ftc.split(',');
				}
				
				if (favorite_tracks.indexOf(trackObj.track_id+"")>-1) {
					return 'icon-btn-favorite-active';
				}
				else {
					return 'icon-btn-favorite';
				}
			},
			setFavoriteStatus(trackObj, iconObj) {
				iconObj.currentTarget.className = "icon-btn-favorite-changing";
				axios.get('/user_like_track', 
						{ 
							params: {track_id: trackObj.track_id},
							headers: {"Authorization" : 'Bearer '+window.localStorage.getItem('auth_token_default')}, 
						}
					)
					.then(response => {
						this.$cookie.set('favorite_tracks', response.data.favorite_tracks);
						$("#track_block_favico_"+response.data.last_change_id).attr('class', this.getFavoriteStatus(trackObj));
					})
					.catch(error => console.log(error)) 
				
			},
		}, 
		
    }
</script>

<style scoped type="scss">

</style>
