<template>
    <section id="favorites">
		<div class="dashboard_menu">
			<DashboardMenu></DashboardMenu>
		</div>
		<div class="dashboard_main">
			<div class="dashboard_main__container">
				<div class="container-fluid">
				
					<h1>{{ $t('dashboard.downloads') }}</h1>
					
					<el-pagination
						layout="prev, pager, next"
						:current-page.sync="page"
						:page-size="20"
						:total="tracks.total"
						class="pagination-padding">
					</el-pagination>
					
					<TrackHeader></TrackHeader>
					
					<div>
						<template v-for="track in tracks.data">
							<TrackBlock :track="track" @addToProject='addToProject'></TrackBlock>
						</template>
					</div>
					
					<el-pagination
						layout="prev, pager, next"
						:current-page.sync="page"
						:page-size="20"
						:total="tracks.total"
						class="pagination-padding">
					</el-pagination>
					
					
				</div>
				<Footer></Footer>
				<el-dialog
						:title="formTitle"
						:visible.sync="formVisible"
						close-on-click-modal
						class="projects-dialog">
						<TrackForm :initialForm="formData" @addTrackToProjectAction='addTrackToProjectAction' @cancel="formVisible = false" />
					</el-dialog>
			</div>
		</div>
    </section>
</template>

<script>
	import axios from 'axios';
	import Vue from 'vue';
	import VueCookie from 'vue-cookie';
	import DashboardMenu from "./DashboardMenu";
	import TrackHeader from "../../../core/components/TrackHeader";
	import TrackBlock from "../../../core/components/TrackBlock";
	import TrackForm from "../../../core/components/TrackForm";
	import router from '../../../bootstrap/router';
	Vue.use(VueCookie);
	import Footer from "../../../core/components/Footer";
	
    export default {
        name: "Downloads",
		components: {DashboardMenu, TrackForm, TrackHeader, TrackBlock, Footer},
        data: () => ({
			userName: '',
			tracks: {
				data: null, 
				last_page: null, 
				current_page: 1,
				total: null
			},
			page: 1,
			formVisible: false,
			formData: null,
			formTitle: ''
        }),
		watch:{
			page: function () {
                this.setURL();
            }
		},
		created() {
			if (this.$route.query.page !== undefined) {
				this.page = parseInt(this.$route.query.page);
			}
			this.updateData();
		},
		methods: {
			setURL() {
					router.push({ path: '/dashboard/downloads', query: { 
						page: this.page
					}}).catch(()=>{});
			},
			updateData() {
				axios.get('track_downloads', 
					{
						params: {
							page: this.page,
						},
						headers: {"Authorization" : 'Bearer '+window.localStorage.getItem('auth_token_default')}, 
					}
				).then(response => (this.tracks = response.data )).catch(error => console.log(error));
			},
			addTrackToProjectAction(data) {
				axios.get('/add_to_project', 
						{ 
							params: data,
							headers: {"Authorization" : 'Bearer '+window.localStorage.getItem('auth_token_default')}, 
						}
					)
					.then(response => {
					})
					.catch(error => console.log(error)) 
			
				this.formVisible = false
			},
			addToProject(track,eventIcon) {
				this.formTitle = 'Add to Project';
                this.formData = Object.assign({}, {
					track_id: track.track_id, 
				});
                this.formVisible = true;
			},
		}
		
    }
</script>

<style lang="scss" scoped>
	#favorites {
		margin-top: 28px;
		min-height: calc(100vh - 53px);
		display: flex;
	}
</style>
