import TrackList from "./components/TrackList";
import TrackView from "./components/TrackView";

export const routes = [
    {
        path: '/tracks',
        name: 'Tracks',
        component: TrackList,
    },
    {
        path: '/tracks/:label/:album/:track',
        name: 'Show Track',
        component: TrackView,
        hidden: true
    }
]
