<template>
    <div class="footer">
		<div class="row">
			<div class="footer__socials col-md-6">

			</div>
			<div class="footer__links col-md-6">
				<a href="mailto:info@apollomedia.pro">{{ $t('global.email_us') }}</a>
				<a href="mailto:info@apollomedia.pro">{{ $t('global.technical_support') }}</a>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
        name: "Footer",
        data: () => ({
        }),
    }
</script>
<style scoped type="scss">
	.footer {
		width: 100%;
		clear:both;
		margin-top: 50px;
		border-top: 1px solid rgba(255,255,255,0.1);
		padding: 15px 30px;
	}
	.footer__socials {
		text-align: left;
	}
	.footer__socials a img {
		opacity: 0.2;
		transition: all 200ms ease;
		margin-right: 13px;
	}
	.footer__socials a:hover img {
		opacity: 0.9;
	}
	.footer__links {
		text-align:right;
	}
	.footer__links a {
		opacity: 0.2;
		transition: all 200ms ease;
		margin-left: 20px;
	}
	.footer__links a:hover {
		opacity: 0.9;
	}
</style>
