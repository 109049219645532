<template>
	<div class="dropdown">
		<button class="dropdown-btn" onclick="javascript:dropDown(this)">{{ getLanguageString(activeLocale) }}</button>
		<div class="dropdown-content">
			<a href="javascript:void(0);"
			   v-for="locale in locales" 
			   :key="locale.id" 
			   @click="setLocale(locale)" 
			   :class="{ 'is-current': locale === activeLocale }"
			   >{{ getLanguageString(locale) }}</a>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue'
	import VueCookie from 'vue-cookie'
	import router from '../../bootstrap/router';
	
	Vue.use(VueCookie)

  const localeStrings = {
    en: "English",
    ru: "Russian"
  };

  Vue.config.lang = VueCookie.get('locale') || 'en';
 
  //console.log("Locale from cookie = " + Vue.config.lang + ": language = " + localeStrings[Vue.config.lang])

  export default {
    props: {
      locales: {
        type: Array,
        default: ['en']
      },
      showFull: Boolean,
      dropup: Boolean,
      locLabel: {
        type: String,
        default: ''
      }
    },
	
    data: function () {
      return {
        activeLocale: Vue.config.lang
      }
    },
    computed: {
      dropdownLbl: function () {
        return this.locLabel ? this.locLabel : this.activeLocale
      }
    },
    methods: {
      setLocale: function (locale) {
        Vue.config.lang = locale
        this.activeLocale = locale
        this.$cookie.set('locale', locale)
        this.$i18n.locale = Vue.config.lang
		Vue.config.reloadKeywords = 1;
		this.$router.go();
      },
      getLanguageString: function (locale) {
        return this.showFull ? localeStrings[locale] : locale
      }
    }
  }
</script>