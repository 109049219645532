<template>
    <main role="main" class="">
		<Nav ref="navigation_block"></Nav>
		
        <div class="text-center content main-content-root">
            <router-view :key="$route.fullPath"></router-view>
        </div>
		
		
    </main>
</template>

<script>

    import Nav from "./Nav";
	
	import Breadcrumbs from "./Breadcrumbs";
	
	
    export default {
        components: {Nav,Breadcrumbs},
	
    }
</script>

<style lang="scss" scoped>
    
</style>
