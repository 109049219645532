import * as types from './types'
import {actions} from './actions'

export const store = {
    state: {
        alibi_tracks: [],
        alibi_tracksMeta: [],
        alibi_tracksLoading: true,
    },
    getters: {
        alibi_tracks: state => state.alibi_tracks,
        alibi_tracksMeta: state => state.alibi_tracksMeta,
        alibi_tracksLoading: state => state.alibi_tracksLoading,
    },
    mutations: {
        [types.ALIBI_OBTAIN](state, alibi_tracks) {
            state.alibi_tracks = alibi_tracks
        },
        [types.ALIBI_CLEAR](state) {
            state.alibi_tracks = []
        },
        [types.ALIBI_SET_LOADING](state, loading) {
            state.alibi_tracksLoading = loading
        },
        [types.ALIBI_META](state, meta) {
            state.alibi_tracksMeta = meta
        },
    },
    actions
}
