<template>
    <div class="search-multiline-field-root">

		<div class="autosuggest-tracks" id="autosuggest_window">
				<div class="autosuggest-tracks__container">

					<div class="autosuggest-tracks__container_wrap">
						<div class="autosuggest-tracks__buttonEnter"><span @click="submitGeneralSearch()">{{ $t('global.press_enter_for_more_results') }}</span></div>
						<div class="row">
							<div class="col-lg-3 col-md-6 col-sm-12 autosuggest-tracks__tags">
								<h5 style="cursor:pointer" @click="submitGeneralSearch()">{{ $t('global.tags') }} ({{ suggest_tags_total }})</h5>
								<ul>
									<li v-for="tag in suggest_tags">
										<i class="add_word hint-top-right" :data-hint="$t('filter.include')" v-on:click.stop="addKeyword(tag)"></i>
										<i class="exclude_word hint-top-right" :data-hint="$t('filter.exclude')" v-on:click.stop="excludeKeyword(tag)"></i>
										<p v-on:click.stop="addKeyword(tag)">{{ tag }}</p>
									</li>
								</ul>
							</div>
							<div class="col-lg-3 col-md-6 col-sm-12 autosuggest-tracks__tracks">
								<h5 style="cursor:pointer" @click="submitGeneralSearch({search_within: 't'})">{{ $t('global.tracks') }} ({{ $t('global.show_all') }})</h5>
								<ul>
									<li v-for="tracks in suggest_tracks">
										<div class="autosuggest-tracks__tracks_cover">
											<router-link :to="{name: 'Show Track', params: {label: tracks.label_fk, album: tracks.album_num, track: tracks.track_num}}"><img :src="getImgUrl(tracks)" alt=""></router-link>
										</div>
										<div class="autosuggest-tracks__tracks_text">
											<strong><router-link :to="{name: 'Show Track', params: {label: tracks.label_fk, album: tracks.album_num, track: tracks.track_num}}">{{ tracks.primary_title }}</router-link></strong>
											<span><router-link :to="{name: 'Show Track', params: {label: tracks.label_fk, album: tracks.album_num, track: tracks.track_num}}">{{ tracks.composers }}</router-link></span>
										</div>
									</li>
								</ul>
							</div>
							<div class="col-lg-3 col-md-6 col-sm-12 autosuggest-tracks__tracks">
								<h5 style="cursor:pointer" @click="submitAlbumSearch()">{{ $t('global.albums') }} ({{ $t('global.show_all') }})</h5>
								<ul>
									<li v-for="album in suggest_albums">
										<div class="autosuggest-tracks__tracks_cover">
											<router-link :to="{name: 'Show Album', params: {label: album.label_fk, album: album.album_num}}"><img :src="getImgUrl(album)" alt=""></router-link>
										</div>
										<div class="autosuggest-tracks__tracks_text">
											<strong><router-link :to="{name: 'Show Album', params: {label: album.label_fk, album: album.album_num}}">{{ album.album_title }}</router-link></strong>
											<span style="text-transform:uppercase"><router-link :to="{name: 'Show Album', params: {label: album.label_fk, album: album.album_num}}">{{ album.label_fk }}#{{ album.album_num }}</router-link></span>
										</div>
									</li>
								</ul>
							</div>

							<div class="col-lg-3 col-md-6 col-sm-12 autosuggest-tracks__tracks">
								<h5>{{ $t('global.playlist') }}</h5>
								<ul>
									<li v-for="playlist in suggest_playlists">
										<div class="autosuggest-tracks__tracks_cover">
											<router-link :to="{name: 'Show Playlist', params: {id: playlist.id}}"><img :src="getPlylistImgUrl(playlist)" alt=""></router-link>
										</div>
										<div class="autosuggest-tracks__tracks_text">
											<strong><router-link :to="{name: 'Show Playlist', params: {id: playlist.id}}">{{ playlist.title }}</router-link></strong>
											<span><router-link :to="{name: 'Show Playlist', params: {id: playlist.id}}">{{ playlist.tracks }} {{ $t('global.tracks_amount') }}</router-link></span>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
		</div>

		<div class="search-field-root__search-field">
			<div class="search-field-container tracks-search-text">
				<form v-on:submit.prevent="setSearchText">
					<div class="search-field-container_input">
						<input autocomplete="off" v-model="words" type="text" :placeholder="$t('global.find_the_track')" id="search-text" v-on:keyup="keymonitor">
						<button type="submit" class="search-submit-btn"></button>
					</div>
				</form>
			</div>

			<div class="clear-button">
				<button v-on:click="clearAll">{{ $t('global.clear_all') }}</button>
			</div>
		</div>

		<div class="search-field-root__search-filter">
			<div style="position:relative" class="search-field-root__search-filter_title hint-top-left-s-small" :data-hint="$t('filter.allows_to_limit_search_fields')">{{ $t('filter.search_within') }}:</div>
			<div class="search-field-root__search-filter_input">
				<label class="checkbox_style">{{ $t('filter.track_title') }}
					<input type="checkbox" v-model="title">
					<span></span>
				</label>
			</div>
			<div class="search-field-root__search-filter_input">
				<label class="checkbox_style">{{ $t('filter.description_and_tags') }}
					<input type="checkbox" v-model="description">
					<span></span>
				</label>
			</div>
			<div class="search-field-root__search-filter_input">
				<label class="checkbox_style">{{ $t('filter.composer') }}
					<input type="checkbox" v-model="composer">
					<span></span>
				</label>
			</div>
			<div class="search-field-root__search-filter_input_last">
				<label class="checkbox_style">{{ $t('filter.with_stems_only') }}
					<input type="checkbox" v-model="stems">
					<span></span>
				</label>
			</div>
		</div>

	</div>


</template>

<script>
	import router from '../../../bootstrap/router';
    import axios from 'axios';

	var cancel = null;
	var CancelToken = axios.CancelToken;

    export default {
		props: ['close_suggest'],
		data() {
			return {
				album_covers_server: config.albumCoversServer,
				playlists_covers_server: config.playlistCoversServer,

				enableWatchers: false,
				words: '',
				savedWords: '',
				stems: false,

				suggest_tags: [],
				suggest_tags_total: 0,
				suggest_tracks: [],
				suggest_tracks_total: 0,
				suggest_albums: [],
				suggest_albums_total: 0,
				suggest_playlists: [],
				suggest_playlists_total: 0,
				delay: null,

				title: false,
				description: true,
				composer: false,
			};
		},
		mounted() {
			$("#overlay-block").fadeOut();
			$("#autosuggest_window").fadeOut();
			let wordsSet = [];
			if (this.$route.query.search !== undefined) {
				this.words = this.$route.query.search;
				this.savedWords = this.$route.query.search;
			}
			if (this.$route.query.stems !== undefined) {
				if (this.$route.query.stems == '1') {
					this.stems = true;
				}
			}


			if (this.$route.query.search_within !== undefined) {
				if (this.$route.query.search_within.indexOf('t') > -1) {
					this.title = true;
				}
				else {
					this.title = false;
				}
				if (this.$route.query.search_within.indexOf('d') > -1) {
					this.description = true;
				}
				else {
					this.description = false;
				}
				if (this.$route.query.search_within.indexOf('c') > -1) {
					this.composer = true;
				}
				else {
					this.composer = false;
				}
			}

			$("#search-text").focus();
			this.enableWatchers = true;
		},
		methods: {
			setSearchText: function(event) {
				this.$emit('setSearchText', {
					text: this.words,
				})
			},
			submitGeneralSearch: function(filters) {
                if (filters !== undefined && filters.search_within.indexOf('t') > -1) {
                    this.title = true;
                }
				this.$emit('setSearchText', {
					text: this.words,
                    search_within: {
                        title: this.title,
                        description: this.description,
                        composer: this.composer
                    }
				})
			},
			submitAlbumSearch: function() {
				router.push({ path: '/albums', query: { search: this.words, page: 1 } })
			},
			clearAll:function() {
				this.$emit('setSearchText', {
					text: '',
				})
			},
			addKeyword(word) {
				this.$emit('setSearchText', {
					text: this.savedWords.trim()+" "+word.toLowerCase(),
				})
			},
			excludeKeyword(word) {
				let wordsArr = word.split(" ");
				let wordsNegative = "-"+wordsArr.join(" -");
				this.$emit('setSearchText', {
					text: this.savedWords.trim()+" "+wordsNegative.toLowerCase(),
				})
			},


			keymonitor:function(event) {
				clearTimeout(this.delay);
				if (event.key === "Escape") {
					$("#overlay-block").fadeOut('fast');
					$("#autosuggest_window").fadeOut('fast');
					if (cancel != undefined) {
						cancel();
					}
					clearTimeout(this.delay);
					return;
				}
				if (event.key === "Backspace") {
					this.savedWords = this.words;
				}

				if (event.key === "Enter" && event.target.value !== "") {
					$("#overlay-block").fadeOut('fast');
					$("#autosuggest_window").fadeOut('fast');
					if (cancel != undefined) {
						cancel();
					}
					clearTimeout(this.delay);
					this.setSearchText();
					return;
				}

				if (event.target.value.length >= 2) {
					if (cancel != undefined) {
						cancel();
					}
					this.startRequestDelay();
				}
			},
			startRequestDelay: function() {
				var that = this;
				this.delay = setTimeout(function() { that.fetchSuggest(); }, 300);

			},
			fetchSuggest: function() {
				axios({
					method: "get",
					url: "/tracks_autosuggest",
					cancelToken: new CancelToken(function executor(c) {
						cancel = c;
					}),
					params: {
						words: this.words,
						saved_words: this.savedWords
					}
				}).then(response => {
					if (typeof response !== undefined) {
						if (typeof response.data !== undefined) {
							if (typeof response.data.tags !== undefined) {
								this.suggest_tags = response.data.tags;
								this.suggest_tags_total = response.data.tags_total;

								this.suggest_tracks = response.data.tracks;
								this.suggest_tracks_total = response.data.tracks_total;

								this.suggest_albums = response.data.albums;
								this.suggest_albums_total = response.data.albums_total;

								this.suggest_playlists = response.data.playlists;
								this.suggest_playlists_total = response.data.playlists_total;

								clearTimeout(this.delay);
							}
						}
					}
				})
				.catch(error => error);
			},
			getImgUrl: function(track)
			{
				return this.album_covers_server + track.label_fk + "/" + track.album_num + ".jpg";
			},
			getPlylistImgUrl: function(album)
			{
				return this.playlists_covers_server + album.id + ".jpg";
			}
		},
		watch:{
			close_suggest: function() {
				if (this.close_suggest)	{

					$("#overlay-block").fadeOut('fast');
					$("#autosuggest_window").fadeOut('fast');
					this.$emit('suggestClosed', {

					})
				}
			},


			suggest_tags_total: function() {
				if (this.suggest_tags_total >= 1 || this.suggest_tracks_total >= 1 || this.suggest_albums_total >= 1 || this.suggest_playlists_total >= 1) {
					$("#overlay-block").fadeIn('fast');
					$("#autosuggest_window").fadeIn('fast');
				}
				else {
					$("#overlay-block").fadeOut('fast');
					$("#autosuggest_window").fadeOut('fast');
				}
			},
			suggest_tracks_total: function() {
				if (this.suggest_tags_total >= 1 || this.suggest_tracks_total >= 1 || this.suggest_albums_total >= 1 || this.suggest_playlists_total >= 1) {
					$("#overlay-block").fadeIn('fast');
					$("#autosuggest_window").fadeIn('fast');
				}
				else {
					$("#overlay-block").fadeOut('fast');
					$("#autosuggest_window").fadeOut('fast');
				}
			},
			suggest_albums_total: function() {
				if (this.suggest_tags_total >= 1 || this.suggest_tracks_total >= 1 || this.suggest_albums_total >= 1 || this.suggest_playlists_total >= 1) {
					$("#overlay-block").fadeIn('fast');
					$("#autosuggest_window").fadeIn('fast');
				}
				else {
					$("#overlay-block").fadeOut('fast');
					$("#autosuggest_window").fadeOut('fast');
				}
			},
			suggest_playlists_total: function() {
				if (this.suggest_tags_total >= 1 || this.suggest_tracks_total >= 1 || this.suggest_albums_total >= 1 || this.suggest_playlists_total >= 1) {
					$("#overlay-block").fadeIn('fast');
					$("#autosuggest_window").fadeIn('fast');
				}
				else {
					$("#overlay-block").fadeOut('fast');
					$("#autosuggest_window").fadeOut('fast');
				}
			},
            stems: function () {
				if (!this.enableWatchers) { return; }
                this.$emit('setStems', {
					text: this.stems,
				})
            },
			title: function () {
				if (!this.enableWatchers) { return; }
                this.$emit('setSearchWithin', {
					title: this.title,
					description: this.description,
					composer: this.composer
				})
            },
			description: function () {
				if (!this.enableWatchers) { return; }
                this.$emit('setSearchWithin', {
					title: this.title,
					description: this.description,
					composer: this.composer
				})
            },
			composer: function () {
				if (!this.enableWatchers) { return; }
                this.$emit('setSearchWithin', {
					title: this.title,
					description: this.description,
					composer: this.composer
				})
            }
		}
    }
</script>

<style scoped type="scss">

</style>
