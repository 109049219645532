<template>
    <div class="search-multiline-field-root">
		<div class="search-field-root__search-field">
			<div class="search-field-container tracks-search-text">
				<form v-on:submit.prevent="setSearchText">
					<div class="search-field-container_input">
						<input autocomplete="off" v-model="words" type="text" :placeholder="$t('global.find_the_track')" id="search-text" v-on:keyup="keymonitor">
						<button type="submit" class="search-submit-btn"></button>
					</div>
				</form>
			</div>

			<div class="clear-button">
				<button v-on:click="clearAll">{{ $t('global.clear_all') }}</button>
			</div>
		</div>

		<div class="search-field-root__search-filter">
			<div style="position:relative" class="search-field-root__search-filter_title">{{ $t('filter.search_within') }}:</div>
			<div class="search-field-root__search-filter_input">
				<label class="checkbox_style">{{ $t('filter.music_tracks') }}
					<input type="radio" v-model="search_within" value="music_tracks">
					<span></span>
				</label>
			</div>
			<div class="search-field-root__search-filter_input">
				<label class="checkbox_style">{{ $t('filter.sound_fx') }}
					<input type="radio" v-model="search_within" value="sound_fx">
					<span></span>
				</label>
			</div>
			<div class="search-field-root__search-filter_input">
				<label class="checkbox_style">{{ $t('filter.game_loops') }}
					<input type="radio" v-model="search_within" value="game_loops">
					<span></span>
				</label>
			</div>
		</div>

	</div>


</template>

<script>
	import router from '../../../bootstrap/router';
    import axios from 'axios';

    export default {
		data() {
			return {
				words: '',

                search_within: 'music_tracks'
			};
		},
		mounted() {

			let wordsSet = [];
			if (this.$route.query.search !== undefined) {
				this.words = this.$route.query.search;
			}
            if (this.$route.query.search_within !== undefined) {
                this.search_within = this.$route.query.search_within;
            }
			$("#search-text").focus();
		},
		methods: {
			setSearchText: function(event) {
				this.$emit('setSearchText', {
					text: this.words,
                    search_within: this.search_within
				})
			},
			clearAll:function() {
				this.$emit('setSearchText', {
					text: '',
				})
			},
            keymonitor:function(event) {
                if (event.key === "Enter" && event.target.value !== "") {
                    this.setSearchText();
                    return;
                }
            },
		},
		watch:{
            search_within: function() {
                this.setSearchText()
            },
		}
    }
</script>

<style scoped type="scss">

</style>
