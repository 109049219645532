import * as types from './types'
import {actions} from './actions'

export const store = {
    state: {
        tracks: [],
        tracksMeta: [],
		sample: [],
        tracksLoading: true,
    },
    getters: {
        tracks: state => state.tracks,
		sample: state => state.sample,
        tracksMeta: state => state.tracksMeta,
        tracksLoading: state => state.tracksLoading,
    },
    mutations: {
        [types.TRACK_OBTAIN](state, tracks) {
            state.tracks = tracks
        },
		[types.TRACK_SAMPLE](state, sample) {
            state.sample = sample
        },
        [types.TRACK_CLEAR](state) {
            state.tracks = []
        },
        [types.TRACK_SET_LOADING](state, loading) {
            state.tracksLoading = loading
        },
        [types.TRACK_META](state, meta) {
            state.tracksMeta = meta
        },
    },
    actions
}