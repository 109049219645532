import axios from 'axios'

const API_ENDPOINT = 'alibi'

export default {

    all(data) {
        return axios.get(API_ENDPOINT, {params: data})
    },

    find(slug) {
        return axios.get(API_ENDPOINT + '/album/' + slug)
    },

    track(slug, track_id) {
        return axios.get(API_ENDPOINT + '/track/' + slug + '/' + track_id)
    }
}
