import * as types from './types'
import playlistApi from '../api'

export const actions = {
    async [types.PLAYLIST_FETCH]({commit}, data = null) {
        commit(types.PLAYLIST_SET_LOADING, true)
        const response = await playlistApi.all(data)
        commit(types.PLAYLIST_OBTAIN, response.data.data)
        commit(types.PLAYLIST_META, response.data.meta)
        commit(types.PLAYLIST_SET_LOADING, false)
    },
}
