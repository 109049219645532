<template>
    <section id="album" class="album_list_container">
		<AlbumSearchForm @setLabel='setLabel' @setSearchText='setSearchText'></AlbumSearchForm>
		
		
		<div class="container-fluid">
			<div class="row">
				<div class="col-lg-4 col-md-4 col-sm-12 search-results-info" v-html="getImgSearchResultInfo()"></div>
				<div class="col-lg-4 col-md-3 col-sm-12"><h1>{{ $t('global.albums') }}</h1></div>
				<div class="col-lg-4 col-md-5 col-sm-12 search-order-container"><AlbumOrder @setOrder='setOrder'></AlbumOrder></div>
			</div>
		</div>

		<el-pagination
            layout="prev, pager, next"
            :current-page.sync="page"
            :page-size.sync="globalPageSize"
            :total="albumsMeta.total"
            class="pagination-padding">
        </el-pagination>
		

		<div class="container-fluid">
        <!-- table -->
        <div class="row">
			<div class="col-lg-2 col-md-3 col-sm-4 col-xs-6 album-block_root" v-for="album in albums" :key="album.album_id">
				<div class="album-block">
						<div class="album-block_img" :style="getImgUrl(album_covers_server, album.label_fk, album.album_num)">
							<div class="album-block_img_serv">
								<span v-if="album.tracks_amount > 0"><strong>{{ album.tracks_amount }}</strong> {{ $t('global.tracks_amount') }}</span>
								<router-link class="album-block_img_icon" :to="{name: 'Show Album', params: {label: album.label_fk, album: album.album_num}}">	
								</router-link>
							</div>
						</div>
				</div>
				<div class="album-block-title"><router-link class="album-block_img_icon" :to="{name: 'Show Album', params: {label: album.label_fk, album: album.album_num}}">{{ album.album_title }}</router-link></div>
				<div class="album-block-code"><router-link class="album-block_img_icon" :to="{name: 'Show Album', params: {label: album.label_fk, album: album.album_num}}">#{{ album.label_fk }}#{{ album.album_num }}</router-link></div>
			</div>
		</div>
		</div>

		
		<el-pagination
            layout="prev, pager, next"
            :current-page.sync="page"
            :page-size.sync="globalPageSize"
            :total="albumsMeta.total"
            class="pagination-padding">
        </el-pagination>

		<Footer></Footer>
    </section>
</template>

<script>

    import {mapActions, mapGetters, mapMutations} from 'vuex'
    import {ALBUM_FETCH, ALBUM_OBTAIN} from "../store/types";
    import albumApi from '../api'
	import AlbumSearchForm from "./AlbumSearchForm";
	import AlbumOrder from "./AlbumOrder";
    import router from '../../../bootstrap/router';
	import Footer from "../../../core/components/Footer";
	
    export default {
        name: "AlbumList",
        components: {AlbumSearchForm,AlbumOrder,Footer},
        data: () => ({
            sort: 'created',
			order: 'desc',
            filters: {
                search: '',
				label: '',
            },
            page: 1,
			globalPageSize: 30,
			album_covers_server: config.albumCoversServer
        }),
		
        computed: {
            ...mapGetters(['albums', 'albumsMeta', 'albumsLoading']),
        },
        created() {
			if (!this.$auth.check()) {
				window.location = '/login';
			}
			else {
				this.filters.label = this.$route.query.label;
				this.filters.search = this.$route.query.search;
				this.sort = this.$route.query.sort;
				this.order = this.$route.query.order;
				this.page = parseInt(this.$route.query.page);
				this.globalPageSize = 30;
				this.fetchData()
			}
        },
        watch:{
            page: function () {
				this.setURL();

            },
        },
        methods: {
            ...mapActions([ALBUM_FETCH]),
            ...mapMutations([ALBUM_OBTAIN]),

            fetchData() {
				if (!this.$auth.check()) {
					return;
				}
                let params = {
                    page: this.page,
                    search: this.filters.search,
					label: this.filters.label,
                    sort: this.sort,
					order: this.order,
                    pageSize: this.globalPageSize
                };
                this[ALBUM_FETCH](params)
            },
            clearSearch() {
                this.filters.search = '';
                this.fetchData();
            },
			setLabel (data) {
            	if (data.label == this.filters.label) {
            		return;
							}
				this.page = 1;
				this.filters.label = data.label;
				this.setURL();
				this.fetchData();
			},
			setSearchText (data) {
				this.page = 1;
				this.filters.search = data.text;
				this.setURL();
				this.fetchData();
			},
			setOrder (data) {
				this.sort = data.sort;
				this.order = data.order;
				this.setURL();
				this.fetchData();
			},
			setURL() {
				/*
					let queryVals = [];
				if (this.filters.label) {
					queryVals['label'] = this.filters.label;
				}
				if (this.filters.search) {
					queryVals['search'] = this.filters.search;
				}
*/

				router.push({ path: 'albums', query: { 
					label: this.filters.label, 
					search: this.filters.search, 
					sort: this.sort,
					order: this.order,
					page: this.page
				}}).catch(()=>{});
			},
			getImgUrl(server,label,album) {
				return "background-image:url('"+server+''+label+'/'+album+".jpg')";
			},
			getImgSearchResultInfo() {
				if (this.albumsMeta.from === undefined) {
					return '';
				}
				return this.$t('global.album') + ' <span>' + (this.albumsMeta.from + 1) + '</span> - <span>' + this.albumsMeta.to + '</span>, '+ this.$t('global.results')+': <span>' + this.albumsMeta.total + '</span>';
			}
        }
    }
</script>
<style lang="scss" scoped>
	.album_list_container {
		padding-top: 100px;  
		padding-bottom: 100px;
	}
	@media(max-width: 767px) {
		.album_list_container {
			padding-top: 130px;  
		}
		.search-order-container {
			text-align: center;
			.order-block {
				float: none;
				justify-content: center;
			}
		}
	}
	
</style>
