import ruLocale from 'element-ui/lib/locale/lang/ru-RU'

export const messages = {
    global: {
        ok: 'Ok',
        cancel: 'Отмена',
		loading: 'Загрузка...',
		yes: 'Да',
        save: 'Сохранить',
		copy_link: 'Копировать ссылку',
        add: 'Добавить',
        edit: 'Редактировать',
        delete: 'Удалить',
        new: 'Новый',
        search: 'Поиск',
		premium: 'Премиум треки',
		albums: 'Альбомы',
        latest_albums: 'Новые альбомы',
        latest_alibi_albums: 'Новые альбомы ALIBI MUSIC',
		tracks: 'Треки',
		show_all: 'показать все',
		press_enter_for_more_results: 'Нажмите ENTER, чтобы увидеть больше результатов',
		tags: 'Теги',
		sound_effects: 'Звуковые эффекты',
		album: 'Альбом',
		projects: 'Проекты',
		project: 'Проект',
		select_label: "Укажите лейбл",
		find_the_album: "Поиск альбома",
		find_the_track: "Поиск трека",
		sfx: 'Звуковые эффекты',
		playlist: 'Плейлисты',
		one_playlist: 'Плейлист',
		view_all_albums: 'Все альбомы',
		view_all_playlists: 'Все плейлисты',
		tracks_amount: 'треков',
		results: 'результатов',
        unknown_server_error: 'Неизвестная ошибка сервера',
		error: 'Ошибка',
		label: 'Лейбл',
		track: 'Трек',
		all_albums_of_this_labels: 'Все альбомы лейбла',
		open_album: 'Открыть альбом',
		open_track: 'Открыть трек',
		login_to_download_a_track: 'Пожалуйста, авторизуйтесь, чтобы скачивать треки',
		clear_all: 'Очистить',
		favorite: 'Избранное',
		sec: 'сек',
		min: 'мин',
		max: 'Макс',
		email_us: 'Написать нам',
		technical_support: 'Поддержка',
		about_apollomusic: 'О Apollomusic',
		our_projects: 'Наши проекты',
		return_to_search: 'Вернуться к поиску',
        There_are_no_results_that_match_your_search: 'Нет результатов, соответствующих вашему запросу',
        license_for_the_ALIBI_catalog_title: 'Лицензия на каталог ALIBI',
        please_contact_us_alibi: 'Пожалуйста, свяжитесь с нами (<a href="mailto:jelena@apollomedia.pro">jelena@apollomedia.pro</a>) для уточнения правил использования данного каталога',
        form: {
            rules: {
                required: 'Поле "{fieldName}" обезательно',
                email: 'Пожалуйста введите корректный email',
                min: 'Длинна поля "{fieldName}" должна быть больше {attribute} символов',
                max: 'Длинна поля "{fieldName}" должна быть меньше {attribute} символов',
                password_repeat: {
                    different: 'Пароли не совпадают',
                }
            }
        },
        Clear_listened: 'Очистить прослушанные',
    },

	track: {
		title: 'Название',
		bpm: 'BPM',
		time: 'Время',
		versions: 'Версий',
		stems: 'Стемов',
		download: 'Скачать',
		added: 'Добавлен',
		tempo: 'Темп',
		downloaded: 'Загружен',
		published: 'Опубликован'
	},
	project: {
		download_project: 'Скачать проект',
		share_project: 'Копировать ссылку',
		copy_link: 'Скопировать ссылку',
		project_is_shared: 'Проект доступен по ссылке',
		cancel_sharing_text: 'В текущий момент времени проект открыт и доступен по ссылке',
		cancel_sharing: 'Закрыть доступ',
		errors: {
			project_size_max: 'Невозможно скачать проект, в котором больше 100 треков!',
			project_size_min: 'В проекте нет треков!'
		}
	},
	sort: {
		by: 'Сортировать',
		sort: 'Сортировать',
		relevance: 'Соответствие',
        title: 'Название',
        album_title: 'Название альбома',
		created: 'Добавлен',
		added: 'Добавлен',
		downloads: 'Загрузки',
		high_to_low: 'По убыванию',
		low_to_high: 'По возрастанию',
		reset: 'Сбросить',
		published: 'Опубликован',
		bpm: 'BPM',
		length: 'Длительность',
		random: 'Случайный порядок',
	},
    auth: {
		create_an_account: 'Регистрация',
        token_expired_alert_title: 'Сессия истекла!',
        token_expired_alert_text: 'Пожалуйска зайдите слова.',
		forgot_password: 'Забыли пароль?',
		invalid_login_credentials: 'Пользователь с таким email и паролем не найден!',
        verification: {
            resend_link: 'Отправить имейл о верификации еще раз',
            resend_title: 'Отправка имейла о верификации',
            resend_button: 'Отправить',
            failed: 'Ссылка не действительная.',
        },
        resend_verification_link: 'Отправить имейл о верификации еще раз',
        login: {
            title: 'Войти',
            submit_button: 'Войти',
            email_label: 'Email',
            password_label: 'Пароль',
        },
        logout: {
            title: 'Выйти',
        },
        register: {
            title: 'Зарегестрироваться',
            name_label: 'Имя',
            email_label: 'Email',
            password_label: 'Пароль',
            password_confirmation_label: 'Повторите пароль',
            submit_button: 'Отправить',
            success: 'Спасибо за регистрацию.',
			sign_up: 'Регистрация',
        },
        logout_confirm: {
            title: 'Подтвердите выход',
            text: 'Вы будете розлогинены',
            button_ok: 'Ok',
            button_cancel: 'Отмена',
        }
    },
    setting: {
        profile: {
            name: 'Имя',
            email: 'Email',
        }
    },
	dashboard: {
		title: 'Моя страница',
		favorites: 'Избранное',
		my_projects: 'Мои проекты',
		downloads: 'Мои загрузки',
		clear_all: 'Очистить избранное',
		clear_all_confirm: 'Вы уверены, что хотите удалить избранное?',
		copy_to_playlist: 'Скопировать в Проект'
	},
	filter: {
		genre: 'Жанр',
		mood: 'Настроение',
		instruments: 'Инструменты',
		topic: 'Тематика',
		performance: 'Исполнение',
		advanced: 'Дополниельно',
		with_stems: 'Стэмы',
		vocal: 'Вокал',
		include: 'Включить',
		exclude: 'Исключить',
		label: 'Лейбл',
		song_length: 'Длительность',
		tempo: 'Темп',
		bpm: 'BPM',
		era: 'Эра',
		similar_track: 'Похожий трек',
		search_within: 'Искать по',
		track_title: 'Заголовку',
		description_and_tags: 'Описанию и тегам',
		composer: 'Композитору',
		with_stems_only: 'Только со стэмами',
		allows_to_limit_search_fields: 'Позволяет ограничить поля поиска',
        music_tracks: 'Музыкальные композиции',
        sound_fx: 'Звуковые эффекты',
        game_loops: 'Игровые композиции',

		tempos: {
			very_fast: 'Очень быстро',
			fast: 'Быстро',
			medium_fast: 'Средне-быстро',
			medium: 'Средне',
			medium_slow: 'Средне-медленно',
			slow: 'Медленно',
			very_slow: 'Очень медленно'
		}

	},
    ...ruLocale
}
