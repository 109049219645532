<template>
    <header>
        <MobileMenu></MobileMenu>
        <div class="mob-menu-btn" id="mobile-menu-icon" v-on:click="showMobileMenu">
            <div></div>
            <div></div>
            <div></div>
        </div>
		<div class="logo">
			<router-link :to="{ name: 'index' }"><span></span></router-link>
		</div>


		<!-- MAIN MENU -->
		<div class="main-menu">
			<div class="main-menu_tracks-link">
				<router-link :to="{ name: 'Tracks' }">
                    <div class="search-link">
                        <svg type="primary" width="20px" height="20px">
                            <svg viewBox="-294 386 22 22" id="find"><path d="M-272.3 406.3l-6.5-6.5c1.1-1.4 1.8-3.3 1.8-5.3 0-4.7-3.8-8.5-8.5-8.5s-8.5 3.8-8.5 8.5 3.8 8.5 8.5 8.5c2 0 3.8-.7 5.3-1.8l6.5 6.5c.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4zm-19.7-11.8c0-3.6 2.9-6.5 6.5-6.5s6.5 2.9 6.5 6.5-2.9 6.5-6.5 6.5-6.5-2.9-6.5-6.5z"></path></svg>
                        </svg>
                    </div>
                    APOLLO MEDIA</router-link>
			</div>
            <router-link :to="{ name: 'ALIBI' }">ALIBI MUSIC</router-link>
			<router-link :to="{ name: 'Premium' }">{{ $t('global.premium') }}</router-link>
			<router-link :to="{ name: 'SFX' }">{{ $t('global.sfx') }}</router-link>
			<router-link :to="{ name: 'Albums' }">{{ $t('global.albums') }}</router-link>
			<router-link :to="{ name: 'Playlists' }">{{ $t('global.playlist') }}</router-link>
			<template v-if="$auth.check()">
				<router-link :to="{ name: 'Projects' }">{{ $t('global.projects') }}</router-link>
			</template>
		</div>
		<!-- // MAIN MENU -->

		<!-- RIGHT MENU -->
		<div class="main-right-menu">

			<div class="main-right-menu_auth">
				<template v-if="$auth.check()">
					<router-link :to="{ name: 'Dashboard' }" id="username_cont">{{ username }}</router-link>
					<button v-on:click="logOut" class="logout-btn"></button>
				</template>
				<template v-else>
					<div class="main-right-menu_auth_login">
						<router-link :to="{ name: 'Login' }">
							{{ $t('auth.login.title') }}
						</router-link>
					</div>

					<!-- <router-link :to="{ name: 'Register' }">
						{{ $t('auth.register.title') }}
					</router-link> -->
				</template>
			</div>
			<div class="main-right-language">
				  <LocaleSwitcher :locales="['en', 'ru']" :show-full="true" ></LocaleSwitcher>
			</div>
		</div>
		<!-- // RIGHT MENU -->



	</header>

</template>

<script>
    import axios from 'axios';
    import Vue from 'vue';
    import VueCookie from 'vue-cookie';
	Vue.use(VueCookie);
    import LocaleSwitcher from "./LocaleSwitcher";
    import MobileMenu from "./MobileMenu";
    export default {
		name: "Nav",
        components: {LocaleSwitcher, MobileMenu},
		data() {
            return {
				username: '',
            }
        },
		mounted() {
			if (this.$auth.check() && (VueCookie.get('username') === "" || VueCookie.get('username') === undefined || VueCookie.get('username') === null)) {
				try {
					axios.get('/user_info',
						{
							headers: {"Authorization" : 'Bearer '+window.localStorage.getItem('auth_token_default')},
						}
					)
					.then(response => {
						VueCookie.set('username', response.data.firstname, { expires: "31d" });
						VueCookie.set('favorite_tracks', response.data.favorite_tracks, { expires: "31d" });
                        this.username = response.data.firstname;
					})
					.catch(error => console.log(error));
				}
				catch (e) {
					console.log(e);
				}
			}
			this.username = VueCookie.get('username');
		},
		methods: {
			logOut() {
				VueCookie.set('username', '');
				VueCookie.set('favorite_tracks', '');
				axios.post(process.env.MIX_API_ENDPOINT + 'auth/logout')
                    .then(() => location.href = '/')
			},
			showMobileMenu: function(event) {
				if ($("#mobile-menu-icon").hasClass("menu-opened")) {
					$(".mobile-menu").css('opacity', 0);
					$(".mobile-menu").css('transform', 'translateX(-100%)');
					$("#mobile-menu-icon").removeClass("menu-opened");
				}
				else {
					$(".mobile-menu").css('opacity', 1);
					$(".mobile-menu").css('transform', 'translateX(0)');
					$("#mobile-menu-icon").addClass("menu-opened");
				}
			}
		}
    }
</script>

<style scoped type="scss">
</style>
