<template>
    <section id="dashboard">
		<div class="dashboard_menu">
			<DashboardMenu></DashboardMenu>
		</div>
		<div class="dashboard_main">
			<div class="dashboard_main__container">
				<div class="container-fluid">
					<h1>{{ $t('dashboard.my_projects') }}</h1>
					
					<el-col :span="24" class="m-t-10">
						<el-form :inline="true" :model="filters" @submit.native.prevent="fetchData" size="mini">
							<el-form-item>
								<el-button type="primary" @click="handleAdd" icon="el-icon-plus">{{$t('global.add')}}</el-button>
							</el-form-item>

							<el-form-item class="mr-0 float-right">
								<el-input v-model="filters.search" @input="applySearch" :placeholder="$t('global.search')">
									<i slot="suffix" class="el-input__icon el-icon-error" v-if="filters.search.length" @click="clearSearch"></i>
								</el-input>
							</el-form-item>

						</el-form>
					</el-col>


					
					
					
					
					
					
					
					     <!-- table -->
						<el-table
							:data="projects"
							highlight-current-row
							v-loading="projectsLoading"
							@sort-change="handleSortChange"
							@filter-change="handleFilterChange"
							class="w-100">
							
							<el-table-column prop="title" label="Title" min-width="200" sortable>
								<template slot-scope="scope">
									<router-link class="el-link el-link--default ellipsis-form" :to="{name: 'Show Project', params: {id: scope.row.id}}">
										<span class="el-link--inner item_name">{{ scope.row.title }}</span>
									</router-link>
								</template>
							</el-table-column>
							<el-table-column prop="created" label="Created" width="200" sortable :sort-orders="sortOrders">
								<template slot-scope="scope">
									{{ GlobalFormatDate(scope.row.created) }}
								</template>
							</el-table-column>
							<el-table-column label="Actions" width="130" align="right">
								<template slot-scope="scope">
									<el-tooltip
										:open-delay="300"
										placement="top"
										:content="$t('global.edit')">
										<span>
											<el-button
												@click="handleEdit(scope.row)"
												size="mini"
												icon="el-icon-edit">
											</el-button>
										</span>
									</el-tooltip>
									<el-tooltip
										:open-delay="300"
										placement="top"
										:content="$t('global.delete')">
										<span>
											<el-button
												@click="handleDelete(scope.row)"
												type="danger"
												size="mini"
												icon="el-icon-delete">
											</el-button>
										</span>
									</el-tooltip>
								</template>
							</el-table-column>
						</el-table>

					
					
					
					
					
					
					
					
					<!-- pagination -->
					<el-pagination
						layout="sizes, prev, pager, next"
						:current-page.sync="page"
						:page-size.sync="globalPageSize"
						:total="projectsMeta.total"
						class="float-right mt-3 mb-3">
					</el-pagination>

					<!-- form dialog -->
					<el-dialog
						:title="formTitle"
						:visible.sync="formVisible"
						close-on-click-modal
						class="projects-dialog">
						<ProjectForm :initialForm="formData" @saved="saved" @cancel="formVisible = false" />
					</el-dialog>





					
				</div>
			</div>
		</div>
   
    </section>
</template>

<script>
	import Vue from 'vue';
	import axios from 'axios';
    import {mapActions, mapGetters, mapMutations} from 'vuex'
    import {PROJECT_FETCH, PROJECT_OBTAIN} from "../store/types";
	import VueCookie from 'vue-cookie';
    import projectApi from '../api';
    import ProjectForm from "./ProjectForm";
	import DashboardMenu from "./../../dashboard/components/DashboardMenu";
	Vue.use(VueCookie)

    export default {
        name: "ProjectList",
        components: {ProjectForm,DashboardMenu},
        data: () => ({
			userName: '',
            sortBy: 'id,asc',
            sortOrders: ['ascending', 'descending'],
            filters: {
                search: ''
            },
            page: 1,
            formVisible: false,
            formTitle: 'New Project',
            formData: null
        }),
        computed: {
            ...mapGetters(['projects', 'projectsMeta', 'projectsLoading']),
        },
        created() {
			if (!this.$auth.check()) {
				window.location = '/login';
			}
			else {
				this.fetchData();
			}
        },
        watch:{
            page: function () {
                this.fetchData();
            },
            pageSize: function () {
                this.fetchData();
            },
        },
        methods: {
            ...mapActions([PROJECT_FETCH]),
            ...mapMutations([PROJECT_OBTAIN]),
            handleSortChange(val) {
                if (val.prop != null && val.order != null) {
                    let sort = val.order.startsWith('a') ? 'asc' : 'desc';
                    this.sortBy = val.prop + ',' + sort;
                    this.fetchData();
                }
            },
            handleFilterChange() {
                this.fetchData();
            },
            fetchData() {
                let params = {
                    page: this.page,
                    search: this.filters.search,
                    sortBy: this.sortBy,
                    pageSize: this.globalPageSize
                };
                this[PROJECT_FETCH](params)
            },
            handleAdd() {
                this.formTitle = 'New Project';
                this.formData = {};
                this.formVisible = true;
            },
            handleEdit(row) {
                this.formTitle = 'Edit Project';
                this.formData = Object.assign({}, row);
                this.formVisible = true;
            },
            handleDelete(row) {
                this.$confirm('This will permanently delete the project. Continue?', 'Warning', {
                    type: 'warning'
                }).then(() => {
                    projectApi.delete(row.id).then((response) => {
                        this.$message({
                            message: response.data.message,
                            type: response.data.type
                        })
                        this.fetchData()
                    })
                })
            },

					applySearch() {
						this.fetchData();
					},

            clearSearch() {
                this.filters.search = '';
                this.fetchData();
            },
            saved() {
                this.formVisible = false
                this.fetchData()
            }
        }
    }
</script>
<style lang="scss" scoped>
	#dashboard {
		margin-top: 28px;
		min-height: calc(100vh - 53px);
		display: flex;
	}
	
</style>
