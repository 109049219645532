import * as types from './types'
import {actions} from './actions'

export const store = {
    state: {
        latests: [],
        latestsMeta: [],
        latestsLoading: true,
    },
    getters: {
        latests: state => state.latests,
        latestsMeta: state => state.latestsMeta,
        latestsLoading: state => state.latestsLoading,
    },
    mutations: {
        [types.LATEST_OBTAIN](state, latests) {
            state.latests = latests
        },
        [types.LATEST_CLEAR](state) {
            state.latests = []
        },
        [types.LATEST_SET_LOADING](state, loading) {
            state.latestsLoading = loading
        },
        [types.LATEST_META](state, meta) {
            state.latestsMeta = meta
        },
    },
    actions
}
