<template>
    <section id="playlist" class="playlist_list_container">
        
		<div class="container-fluid">
			<div class="row">
				<div class="col-md-4 search-results-info"></div>
				<div class="col-md-4"><h1>{{ $t('global.playlist') }}</h1></div>
				<div class="col-md-4 search-order-container"></div>
			</div>
		</div>
		
		<el-pagination
            layout="prev, pager, next"
            :current-page.sync="page"
            :page-size.sync="globalPageSize"
            :total="playlistsMeta.total"
            class="pagination-padding">
        </el-pagination>
		
		
		<div class="container-fluid">
			<div class="row">
				<div class="col-lg-3 col-md-4 col-sm-4 col-xs-6 playlist-block_root" v-for="playlist in playlists" :key="playlist.id">
					<router-link class="playlist-block" :style="getBackground(playlist.id)" :to="{name: 'Show Playlist', params: {id: playlist.id}}">
						<div class="playlist-block__container">
							<em class="playlist-block__container_count"><strong>{{ getTracksCount(playlist.tracks) }}</strong> {{ $t('global.tracks_amount') }}</em>
							<em class="playlist-block__container_created">{{ getDate(playlist.created) }}</em>
							<span>{{ playlist.name }}</span>
						</div>
					</router-link>
				</div>
			</div>
		</div>
        
		
		<el-pagination
            layout="prev, pager, next"
            :current-page.sync="page"
            :page-size.sync="globalPageSize"
            :total="playlistsMeta.total"
            class="pagination-padding">
        </el-pagination>
		
		
		
		
		<Footer></Footer>
    </section>
</template>

<script>
	import {mapActions, mapGetters, mapMutations} from 'vuex'
    import {PLAYLIST_FETCH, PLAYLIST_OBTAIN} from "../store/types";
    import playlistApi from '../api'
    import router from '../../../bootstrap/router';
	import Footer from "../../../core/components/Footer";
	
	export default {
        name: "PlaylistList",
        components: {Footer},
        data: () => ({
            sortBy: 'id,asc',
            sortOrders: ['ascending', 'descending'],
            filters: {
                search: ''
            },
            page: 1,
        }),
        computed: {
            ...mapGetters(['playlists', 'playlistsMeta', 'playlistsLoading']),
        },
        created() {
			if (!this.$auth.check()) {
				window.location = '/login';
			}
			else {
				this.fetchData()
			}
        },
        watch:{
            page: function () {
                this.fetchData();
            },
            pageSize: function () {
                this.fetchData();
            },
        },
        methods: {
            ...mapActions([PLAYLIST_FETCH]),
            ...mapMutations([PLAYLIST_OBTAIN]),
            handleSortChange(val) {
                if (val.prop != null && val.order != null) {
                    let sort = val.order.startsWith('a') ? 'asc' : 'desc';
                    this.sortBy = val.prop + ',' + sort;
                    this.fetchData();
                }
            },
            handleFilterChange() {
                this.fetchData();
            },
            fetchData() {
				if (!this.$auth.check()) {
					return;
				}
                let params = {
                    page: this.page,
                    search: this.filters.search,
                    sortBy: this.sortBy,
                    pageSize: this.globalPageSize
                };
                this[PLAYLIST_FETCH](params)
            },
            
            clearSearch() {
                this.filters.search = '';
                this.fetchData();
            },
			getBackground(playlistID) {
				return "background-image:url('"+config.playlistCoversServer+''+playlistID+".jpg')";
			},
			getDate(date) {
				return date.split(' ')[0];
			},
			getTracksCount(tracksList) {
				return tracksList.split(',').length;
			}
        }
    }
    
</script>
<style lang="scss" scoped>
	.playlist_list_container {
		padding-top: 40px;  
		padding-bottom: 100px;
	}
	.playlist-block_root {
		padding-bottom: 25px;
	}
	.playlist-block {
		display: block;
		position: relative;
		height: 100%;
		width: 100%;
		margin: 0px;
		border-radius: 5px;
		backface-visibility: hidden;
		background-color: #2a2a2a;
		background-size: cover !important;
	}
	.playlist-block__container {
		display: flex;
		align-items: flex-end;
		-webkit-box-pack: start;
		justify-content: flex-start;
		position: relative;
		height: 100%;
		width: 100%;
		margin: 0px;
		border-radius: 2px;
		backface-visibility: hidden;
		overflow: hidden;
		text-align:left;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover !important;
		padding-bottom: calc(70.3448%) !important;
		span {
			margin: 0px;
			color: rgb(255, 255, 255);
			font-size: 26px;
			line-height: 31px;
			font-weight: 400;
			position: absolute;
			left: 25px;
			bottom: 20px;
			padding-right: 20px;
			text-shadow: 0 0 10px rgba(0,0,0,1), 0 0 15px rgba(0,0,0,1);
		}
		em {
			font-style: normal;
			display: inline-block;
		}
		.playlist-block__container_count {
			position: absolute;
			float: right;
			right: 0px;
			padding: 8px 12px 0px 8px;
			color: rgba(255,255,255,0.7);
			strong {
				color:#fff;
			}
		}
		
		.playlist-block__container_created {
			padding: 8px 8px 0px 12px;	
			opacity: 0.7;
		}
		
	}
</style>
