/**
 * AutoImporting components
 * @param path
 * @returns {function(): *}
 */
const page = path => () => import(/* webpackChunkName: '' */ `./components/${path}`).then(m => m.default || m)

export const routes = [
    {
        path: '/latests',
        name: 'Latests',
        component: page('LatestList'),
    },
    {
        path: '/latests/:id',
        name: 'Show Latest',
        component: page('LatestView'),
        hidden: true
    }
]
