import Dashboard from "./components/Dashboard";
import Favorites from "./components/Favorites";
import FavoritesAlibi from "./components/FavoritesAlibi";
import Downloads from "./components/Downloads";

export const routes = [
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Favorites,
    },
	{
		path: '/dashboard/favorites',
		name: 'Favorites',
		component: Favorites,
	},
	{
		path: '/dashboard/downloads',
		name: 'Downloads',
		component: Downloads,
	}
];
