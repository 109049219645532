<template>
    <section id="sfx" class="track_list_container">
		
		
		<div class="track_list_filter_block sfx_list_filter_block">
			<div class="track_list_filter_block_sub display_flex">
					<div class="track_list_filter_block_sub_cnt">
						
						<div class="track_list_filter_genres_list">
							<ul>
								<li v-for="keyword in keywords"><div v-on:click.stop="addKeyword(keyword)">
										<i class="add_word hint-top-right" :data-hint="$t('filter.include')" v-on:click.stop="addKeyword(keyword)"></i>
										<i class="exclude_word hint-top-right" :data-hint="$t('filter.exclude')" v-on:click.stop="excludeKeyword(keyword)"></i>
										<p>{{ keyword }}</p>
									</div>
								</li>
							</ul>
						</div>
					</div>
			</div>
		</div>
			
			

		<div class="track_list_root">
			<div class="track_list_root_cont">
				<SfxSearchForm @setSearchText='setSearchText'></SfxSearchForm>

				<div class="container-fluid">
					<h1>{{ $t('global.sound_effects') }}</h1>
					<SfxFilters 
						@removeSimilar='removeSimilar' 
						:sfx_sample="sfx_sample"></SfxFilters>
						
					<div class="row">
						<div class="col-md-4 search-results-info" v-html="getImgSearchResultInfo()"></div>
						<div class="col-md-8 search-order-container"><SfxOrder @setOrder='setOrder'></SfxOrder></div>
					</div>
				</div>
				
				
				<el-pagination
					layout="prev, pager, next"
					:current-page.sync="page"
					:page-size.sync="globalPageSize"
					:total="sfxesMeta.total"
					class="pagination-padding">
				</el-pagination>
				
				
				<div class="container-fluid">
					<div class="tracks-list-header-block">
								<div class="col-icon"></div>
								<div>{{ $t('track.title') }}</div>
								<div class="col-bpm">{{ $t('track.bpm') }}</div>
								<div class="col-time">{{ $t('track.time') }}</div>
								<div class="col-download">&nbsp;</div>
								<div class="col-download">&nbsp;</div>
					</div>
					
					<div>
						<div v-for="track in sfxes" :key="track.track_id" v-bind:id="'track_block_' + track.track_id" v-bind:data-tid="track.track_id" class="track_common_block">
							<div class="track-block">
								<div class="track-block_icon" v-bind:id="'track_play_btn_' + track.track_id" v-on:click="playTrack(track)" :style="getImgUrl(album_covers_server, track.label_fk, track.album_num)"></div>
								<div class="track-block_title">
									<strong>{{ track.primary_title }} <span>{{ track.composers }}</span></strong>
									<p>{{ track.description }}</p>
									<p class="lab_alb_trck">
										<a target="_blank" :href="'/albums?label='+track.label_fk.toUpperCase()+'&page=1'" class="hint-top-middle" :data-hint="$t('global.all_albums_of_this_labels')">{{ $t('global.label') }}: {{ track.label_fk.toUpperCase() }}</a> 
										<span>/</span> 
										<a target="_blank" :href="'/albums/'+track.label_fk+'/'+track.album_num" class="hint-top-middle" :data-hint="$t('global.open_album')">{{ $t('global.album') }}: {{ track.album_num.toUpperCase() }}</a>
										<span>/</span> 
										<span>{{ $t('global.track') }}:</span> {{ track.track_num }}
									</p>
									<p class="dates_secondary_info">
										<span><strong>{{ $t('track.added') }}:</strong> {{ track.created }}</span>
										<template v-if="track.album_published">
											<span><strong>{{ $t('track.published') }}:</strong> {{ track.album_published }}</span>
										</template>
									</p>
								</div>
								<div class="track-block_versions">
									<button :style="getVisible(track.versions)" v-on:click="showTracksVersions(track.label_fk, track.album_num, track.track_num, track.track_id)" class="btn-standart">{{ track.versions }} {{ $t('track.versions') }}</button>
								</div>
								<div class="track-block_stems">
									<button :style="getVisible(track.stems)" v-on:click="showTracksStems(track.label_fk, track.album_num, track.track_num, track.track_id)" class="btn-standart">{{ track.stems }} {{ $t('track.stems') }}</button>
								</div>
								
								<div class="track-block_time">{{ track.duration }}</div>
								
								<div class="track-block_ico hint-top-middle" data-hint="Find similar">
									<i v-on:click="showSimilarTracks(track)" class="icon-btn-similar"></i>
								</div>
								
								<div class="track-block_download">
									<template v-if="$auth.check()">
										<i v-on:click="getFileLink(track.label_fk, track.album_num, track.track_num)" target="_blank" class="icon-btn-download"></i>
									</template>
									<template v-else>
										<a href="/login" style="opacity:1; position: relative" class="hint-top-right-s-small icon-btn-download" :data-hint="$t('global.login_to_download_a_track')"></a>
									</template>
								</div>
								
							</div>
							<SfxVersions v-bind:ref="'track_cnt_' + track.track_id"></SfxVersions>

						</div>
					</div>
				</div>
				
				<el-pagination
					layout="prev, pager, next"
					:current-page.sync="page"
					:page-size.sync="globalPageSize"
					:total="sfxesMeta.total"
					class="pagination-padding">
				</el-pagination>


				
				
				<Footer></Footer>
			</div>
		</div>	


				
				
				
		
    </section>
</template>

<script>
	import Vue from 'vue';
	import axios from 'axios';
    import {mapActions, mapGetters, mapMutations} from 'vuex'
    import {SFX_FETCH, SFX_OBTAIN} from "../store/types";
    import sfxApi from '../api'
    import SfxForm from "./SfxForm";
	import SfxSearchForm from "./SfxSearchForm";
	import SfxFilters from "./SfxFilters";
	import SfxOrder from "./SfxOrder";
	import SfxVersions from "./SfxVersions";
	import router from '../../../bootstrap/router';
	import VueCookie from 'vue-cookie';
	import Footer from "../../../core/components/Footer";

	Vue.use(VueCookie)

    export default {
        name: "SfxList",
        components: {SfxForm,SfxSearchForm,SfxFilters,SfxOrder,SfxVersions,Footer},
        data: () => ({
            sort: 'created',
			order: 'desc',
            filters: {
                search: ''
            },
            page: 1,
			globalPageSize: 30,
			album_covers_server: config.albumCoversServer,
			music_server: config.musicServer,
			keywords: null,
			similar: null,
			enableWatchers: false,
        }),
        computed: {
            ...mapGetters(['sfxes', 'sfx_sample','sfxesMeta', 'sfxesLoading']),
        },
        created() {
			if (!this.$auth.check()) {
				window.location = '/login';
			}
			else {
				this.filters.search = this.$route.query.search;
				this.sort = this.$route.query.sort;
				this.order = this.$route.query.order;
				this.similar = this.$route.query.similar;
				this.page = parseInt(this.$route.query.page);
				this.globalPageSize = 30;
				this.keywords = Vue.config.sfxKeywordsCache;
				this.fetchData();
				this.enableWatchers = true;
			}
        },
		beforeCreate() {
			if (Vue.config.sfxKeywordsCache == undefined || Vue.config.reloadKeywords) {
				   axios
					.get('keywords?lang='+Vue.config.lang+'&type=sfx')
					.then(response => (this.keywords = response.data ));   
			}
			else {
				this.keywords = Vue.config.sfxKeywordsCache;
			}
			this.keywords = Vue.config.sfxKeywordsCache;
		},
        watch:{
            page: function () {
                this.fetchData();
            },
            keywords: function() {
				if (!this.enableWatchers) { return; }
				if (this.keywords !== null) {
					Vue.config.sfxKeywordsCache = this.keywords;
				}
			},
        },
        methods: {
            ...mapActions([SFX_FETCH]),
            ...mapMutations([SFX_OBTAIN]),
            
            fetchData() {
				
                let params = {
                    page: this.page,
                    search: this.filters.search,
					sort: this.sort,
					order: this.order,
					pageSize: this.globalPageSize,
					similar: this.similar
                };
                this[SFX_FETCH](params)
            },
			clearSearch() {
                this.filters.search = '';
                this.fetchData();
            },
			showSimilarTracks(track) {
				this.similar = track.track_id;
				this.setURL();
				this.fetchData();
			},
			setSearchText (data) {
				this.page = 1;
				this.filters.search = data.text;
				this.setURL();
				this.fetchData();
			},
			setOrder (data) {
				this.sort = data.sort;
				this.order = data.order;
				this.setURL();
				this.fetchData();
			},
			playTrack(trackObj) { 
				initialPlayer();
				let label = trackObj.label_fk;
				let album = trackObj.album_num;
				let track = trackObj.track_num;
				
				if ($("#jquery_jplayer_obj").data('play-track-id') == trackObj.track_id) {
					if ($("#jquery_jplayer_obj").data('isPlaying')) {
						$("#jquery_jplayer_obj").jPlayer("pause"); 
					}
					else {
						$("#jquery_jplayer_obj").jPlayer("play"); 
					}
				}
				else {
					setPlayerData(trackObj, this.album_covers_server);
					$("#jquery_jplayer_obj").jPlayer("setMedia", {
						mp3: this.music_server + 'stream_music_file.php?label='+label.toLowerCase()+'&album='+album.toLowerCase()+'&track='+track,
					}).jPlayer("play");
				}
			},
			getFileLink(label, album, track) {
				axios.get('/sfx_download', 
						{ 
							params: {
								l: label.toLowerCase(),
								a: album.toLowerCase(),
								t: track
							},
							headers: {"Authorization" : 'Bearer '+window.localStorage.getItem('auth_token_default')}, 
						}
					)
					.then(response => {
						var fileLink = document.createElement('a');
						fileLink.href = response.data.url;
						fileLink.setAttribute('download', response.data.fname+'.mp3');
						document.body.appendChild(fileLink);
						fileLink.click();
					  })
					  .catch(error => console.log(error)) 
			},
			removeSimilar() {
				this.similar = "";
				this.setURL();
				this.fetchData();
			},
			setURL() {
				let queryVals = [];
				queryVals['search'] = this.filters.search;
				
				
				if (this.similar) {
					queryVals['similar'] = this.similar;
				}
				if (this.order) {
					queryVals['order'] = this.order;
				}
				if (this.page) {
					queryVals['page'] = this.page;
				}
				if (this.sort) {
					queryVals['sort'] = this.sort;
				}
				router.push({ path: 'sfx', query: queryVals}).catch(()=>{});
				
			},
			addKeyword(word) {
				this.page = 1;
				if (this.filters.search == undefined || this.filters.search =="") {
					this.filters.search = word.replace(/\s+/g, '_');
				}
				else {
					this.filters.search += ' '+word.replace(/\s+/g, '_');
				}
				this.setURL();
				this.fetchData();
			},
			excludeKeyword(word) {
				this.page = 1;
				if (this.filters.search == undefined || this.filters.search =="") {
					this.filters.search = word.replace(/\s+/g, '_');
				}
				else {
					this.filters.search += ' -'+word.replace(/\s+/g, '_');
				}
				this.setURL();
				this.fetchData();
			},
			getImgSearchResultInfo() {
				if (this.sfxesMeta.from === undefined) {
					return '';
				}
				return this.$t('global.track') + ' <span>' + (this.sfxesMeta.from + 1) + '</span> - <span>' + this.sfxesMeta.to + '</span>, '+ this.$t('global.results')+': <span>' + this.sfxesMeta.total + '</span>';
			},
            getImgUrl(server,label,album) {
				return "background-image:url('"+server+''+label+'/'+album+".jpg')";
			},
			getVisible(countVal) {
				if (countVal<1) {
					return "display:none";
				}
				else {
					return '';
				}
			},
			showTracksVersions(l,a,t,id) {
				this.$refs['track_cnt_'+id][0].show(l,a,t, 1);
			},
			showTracksStems(l,a,t,id) {
				this.$refs['track_cnt_'+id][0].show(l,a,t, 2);
			},
			showSimilarTracks(track) {
				this.similar = track.track_id;
				this.setURL();
				this.fetchData();
			},
        }
    }
</script>
<style lang="scss" scoped>

</style>
