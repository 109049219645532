import * as types from './types'
import trackApi from '../api'

export const actions = {
    async [types.TRACK_FETCH]({commit}, data = null) {
        commit(types.TRACK_SET_LOADING, true)
        const response = await trackApi.all(data)
        commit(types.TRACK_OBTAIN, response.data.data)
		commit(types.TRACK_SAMPLE, response.data.sample)
        commit(types.TRACK_META, response.data.meta)
        commit(types.TRACK_SET_LOADING, false)
    },
}