<template>
	<transition name="fade">
        <div class="popup-modal" v-if="isVisible">
            <div class="window">
                <slot></slot>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
		name: 'PopupModal',

		data: () => ({
			isVisible: false,
		}),

		methods: {
			open() {
				this.isVisible = true
			},

			close() {
				this.isVisible = false
			},
		},
	}
</script>

<style lang="scss" scoped>
	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.3s;
	}
	.fade-enter,
	.fade-leave-to {
		opacity: 0;
	}

	.popup-modal {
		background-color: rgba(0,0,0,0.2);
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 0.5rem;
		display: flex;
		align-items: center;
		z-index: 1111;
	}
	.window {
		background: #4a4a4a;
		border-radius: 5px;
		box-shadow: 2px 3px 12px rgba(0, 0, 0, 0.3);
		max-width: 480px;
		margin-left: auto;
		margin-right: auto;
		padding: 1rem;
	}
</style>
