<template>
    <div class="dashboard_menu__nav">
		<ul>
			<li><router-link :to="{ name: 'Favorites' }"><i class="ico ico_favorites"></i> {{ $t('dashboard.favorites') }}</router-link></li>

			<li><router-link :to="{ name: 'Projects' }"><i class="ico ico_my_projects"></i> {{ $t('dashboard.my_projects') }}</router-link></li>
			<li><router-link :to="{ name: 'Downloads' }"><i class="ico ico_download"></i> {{ $t('dashboard.downloads') }}</router-link></li>
		</ul>
	</div>
</template>

<script>
	export default {
        name: "Dashboard",
        data: () => ({

        }),
    }
</script>

<style lang="scss" scoped>
	.dashboard_menu__nav {
		ul {
			list-style:none;
			margin: 0px;
			padding: 0px 0px 20px 20px;
			li {
				display: block;
				padding: 5px 0;
				a {
					transition: all 200ms ease;
					vertical-align: middle;
					display: block;
					font-size: 15px;
					padding: 4px 10px 4px 0px;
					color:rgba(255,255,255,0.7);
					&.router-link-active {
						border-right: 3px solid #f9b436;
						color:rgba(255,255,255,1);
						.ico {
							opacity: 1;
						}
					}
					&:hover {
						color:rgba(255,255,255,1);
						.ico {
							opacity: 0.8;
						}
					}
				}
			}
		}
		.ico {
			vertical-align: middle;
			display: inline-block;
			height: 18px;
			width: 18px;
			margin-right: 10px;
			opacity: 0.5;
			transition: all 200ms ease;
		}
		.ico_favorites {
			background: url("/css/img/icons/favorites_18_18.png") 0 0 no-repeat;
		}
		.ico_download {
			background: url("/css/img/icons/downloads_18_18.png") 0 0 no-repeat;
		}
		.ico_my_projects {
			background: url("/css/img/icons/my_projects_18_18.png") 0 0 no-repeat;
		}
		.ico_cart {
			background: url("/css/img/icons/cart_18_18.png") 0 0 no-repeat;
		}

	}
</style>
