import * as types from './types'
import {actions} from './actions'

export const store = {
    state: {
        playlists: [],
        playlistsMeta: [],
        playlistsLoading: true,
    },
    getters: {
        playlists: state => state.playlists,
        playlistsMeta: state => state.playlistsMeta,
        playlistsLoading: state => state.playlistsLoading,
    },
    mutations: {
        [types.PLAYLIST_OBTAIN](state, playlists) {
            state.playlists = playlists
        },
        [types.PLAYLIST_CLEAR](state) {
            state.playlists = []
        },
        [types.PLAYLIST_SET_LOADING](state, loading) {
            state.playlistsLoading = loading
        },
        [types.PLAYLIST_META](state, meta) {
            state.playlistsMeta = meta
        },
    },
    actions
}
