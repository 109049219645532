<template>
    <div class="order-block">
		<em>{{ $t('sort.sort') }}</em>
		<ul>
			<li :class="showActiveSort('random')" style="cursor: pointer" @click="setOrder('random', '')"><span>{{ $t('sort.random') }}</span>
			</li>
			
			<li :class="showActiveSort('created')"><span>{{ $t('sort.created') }}</span>
				<ul>
					<li @click="setOrder('created', 'desc')">{{ $t('sort.high_to_low') }}</li>
					<li @click="setOrder('created', 'asc')">{{ $t('sort.low_to_high') }}</li>
				</ul>
			</li>
			
			<li :class="showActiveSort('published')"><span>{{ $t('sort.published') }}</span>
				<ul>
					<li @click="setOrder('published', 'desc')">{{ $t('sort.high_to_low') }}</li>
					<li @click="setOrder('published', 'asc')">{{ $t('sort.low_to_high') }}</li>
				</ul>
			</li>
			
			<li :class="showActiveSort('relevance')"><span>{{ $t('sort.relevance') }}</span>
				<ul>
					<li @click="setOrder('relevance', 'desc')">{{ $t('sort.high_to_low') }}</li>
					<li @click="setOrder('relevance', 'asc')">{{ $t('sort.low_to_high') }}</li>
				</ul>
			</li>
			
			<li :class="showActiveSort('bpm')"><span>{{ $t('sort.bpm') }}</span>
				<ul>
					<li @click="setOrder('bpm', 'desc')">{{ $t('sort.high_to_low') }}</li>
					<li @click="setOrder('bpm', 'asc')">{{ $t('sort.low_to_high') }}</li>
				</ul>
			</li>
			
			<li :class="showActiveSort('length')"><span>{{ $t('sort.length') }}</span>
				<ul>
					<li @click="setOrder('length', 'desc')">{{ $t('sort.high_to_low') }}</li>
					<li @click="setOrder('length', 'asc')">{{ $t('sort.low_to_high') }}</li>
				</ul>
			</li>
		</ul>
	</div>
</template>

<script>

    import axios from 'axios';
    export default {
		data: () => ({
            sort: 'random',
			order: 'desc',
        }),
		mounted() {
		},
		methods: {
			showActiveSort(field) {
				if (this.$route.query.sort === "" || this.$route.query.sort === undefined) {
					var sort = this.sort;
					var order = this.order;
				}
				else {
					var sort = this.$route.query.sort;
					var order = this.$route.query.order;
				}
				if (sort == field) {
					if (field == 'random') {
						return 'active_order_random';
					}
					return 'active_order_'+order;
				}
			},
			setOrder(sort, order) {
				this.$emit('setOrder', {
					sort: sort,
					order: order,
				})
			},
		}
    }
</script>