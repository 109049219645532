<template>
    <section id="sfx_show">
        <div class="text-center">{{ sfx.name }}</div>
    </section>
</template>

<script>

    import sfxApi from "../api"

    export default {
        name: "SfxView",
        components: {},
        data() {
            return {
                sfx: {},
            }
        },
        methods: {
            async fetchSfx() {
                const response = await sfxApi.find(this.$route.params.id)
                this.sfx = response.data.data
            },
        },
        mounted() {
            this.fetchSfx()
        },
    }
</script>

<style lang="scss" scoped>

</style>
