import * as types from './types'
import {actions} from './actions'

export const store = {
    state: {
        placements: [],
        placementsMeta: [],
        placementsLoading: true,
    },
    getters: {
        placements: state => state.placements,
        placementsMeta: state => state.placementsMeta,
        placementsLoading: state => state.placementsLoading,
    },
    mutations: {
        [types.PLACEMENT_OBTAIN](state, placements) {
            state.placements = placements
        },
        [types.PLACEMENT_CLEAR](state) {
            state.placements = []
        },
        [types.PLACEMENT_SET_LOADING](state, loading) {
            state.placementsLoading = loading
        },
        [types.PLACEMENT_META](state, meta) {
            state.placementsMeta = meta
        },
    },
    actions
}
