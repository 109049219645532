import { render, staticRenderFns } from "./AlbumOrder.vue?vue&type=template&id=cb5e4378&scoped=true&"
import script from "./AlbumOrder.vue?vue&type=script&lang=js&"
export * from "./AlbumOrder.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb5e4378",
  null
  
)

export default component.exports