<template>
    <section id="dashboard">
		<div class="dashboard_menu">
			<DashboardMenu></DashboardMenu>
		</div>
		<div class="dashboard_main">

		</div>
    </section>
</template>

<script>
	import axios from 'axios';
	import Vue from 'vue';
	import VueCookie from 'vue-cookie';
	import DashboardMenu from "./DashboardMenu";
	Vue.use(VueCookie);
	
	
    export default {
        name: "Dashboard",
        components: {DashboardMenu},
        data: () => ({
			userName: ''
        }),
		
		created() {
			if (!this.$auth.check()) {
				window.location = '/login';
			}
		},
    }
</script>

<style lang="scss" scoped>
	#dashboard {
		margin-top: 28px;
		min-height: calc(100vh - 53px);
		display: flex;
	}
</style>
