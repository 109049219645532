<template>
	<section id="track" class="track_search_list_container" style="margin-top: 20px; min-height: calc(100vh - 45px)">

        <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>

		<div class="track_list_root">
            <div class="loader_bgnd">
                <div class="loader_bgnd_cont">
                    <div class="loader triangle">
                        <svg viewBox="0 0 86 80">
                            <polygon points="43 8 79 72 7 72"></polygon>
                        </svg>
                    </div>
                </div>
            </div>
			<div class="track_list_root_cont" style="height: calc(100vh - 45px);">

				<div class="container-fluid">
                    <template v-if="showClearListenedButton">
                        <button
                            style="margin-left: 8px; margin-top: -1px;"
                            v-on:click="removeListenedTracks()"
                            class="button-classic float-right">{{ $t('global.Clear_listened') }}</button>
                    </template>
					<h1 v-if="album.album_title">{{ $t('global.album') }} "{{ album.album_title }}"</h1>


                    <div class="row">
                        <div class="col-md-3">
                            <div class="album-block">
                                <div class="album-block_img" :style="img_section_style">
                                    <div class="album-block_img_serv">
                                        &nbsp;
                                    </div>
                                </div>
                            </div>
                            <br>
                            <div style="text-align:left" v-html="album.album_description"></div><br>
                        </div>
                        <div class="col-md-9">

                            <div>
                                <div v-for="track in album.tracks" :key="track.id" v-bind:id="'track_block_' + track.id" v-bind:data-tid="track.id" class="track_common_block" :style="isPlackPlayed(track)">
                                    <div class="track-block">
                                        <div class="track-block_num" v-on:click="playTrack(track.primary)">{{ track.track_num }}</div>
                                        <div class="track-block_icon" v-bind:id="'track_play_btn_' + track.id" v-on:click="playTrack(track)" :style="getImgUrl(track)"></div>
                                        <div class="track-block_title">
                                            <strong>{{ track.title }} <span>{{ track.album_name }}</span></strong>
                                            <p>{{ track.description }}</p>
                                            <p class="lab_alb_trck">
                                                <a target="_blank" :href="'/alibi/track/'+track.album_slug+'/'+track.id"  class="hint-top-middle" :data-hint="$t('global.open_track')">{{ $t('global.track') }}: {{ track.title }}</a>
                                                (<a target="_blank" :href="'/alibi/track/'+track.album_slug+'/'+track.id"  class="hint-top-middle" :data-hint="$t('global.open_track')">#{{ track.id }}</a>)
                                            </p>
                                            <p class="dates_secondary_info">
                                                <span><strong>{{ $t('track.added') }}:</strong> {{ track.release_date }}</span>
                                            </p>
                                        </div>
                                        <div class="track-block_stems">
                                            <button :style="getVisible(track.stems.length)" v-show="track.stems.length > 0" v-on:click="showStems($event)" class="btn-standart">{{ track.stems.length }} {{ $t('track.stems') }}</button>
                                        </div>
                                        <div class="track-block_bpm">{{ track.bpm }} BPM</div>
                                        <div class="track-block_time">{{ formatAsTime(track.duration) }}</div>




                                        <div class="track-block_download">
                                            <div class="track-block_ico hint-top-middle" data-hint="Add to Project">
                                                <template v-if="$auth.check()">
                                                    <i v-on:click="addToProject(track, $event)" class="icon-btn-add-to-proj"></i>
                                                </template>&nbsp;
                                            </div>
                                            <template v-if="$auth.check()">
                                                <i v-on:click="getFileLink(track)" target="_blank" class="icon-btn-download"></i>
                                            </template>
                                            <template v-else>
                                                <a href="/login" style="opacity:1; position: relative" class="hint-top-right-s-small icon-btn-download" :data-hint="$t('global.login_to_download_a_track')"></a>
                                            </template>
                                        </div>

                                    </div>
                                    <div class="track-stems-container">
                                        <div v-for="stem_track in track.stems" :key="stem_track.id">
                                            <div class="track-block">
                                                <div class="track-block_num" v-on:click="playTrack(stem_track)">{{ stem_track.track_num }}</div>
                                                <div class="track-block_icon" v-bind:id="'track_play_btn_' + stem_track.id" v-on:click="playTrack(stem_track)" :style="getImgUrl(stem_track)"></div>
                                                <div class="track-block_title">
                                                    <strong>{{ stem_track.title }} <span>{{ stem_track.version_name }}</span></strong>
                                                    <p>{{ stem_track.description }}</p>
                                                    <p class="lab_alb_trck">
                                                        <a target="_blank" :href="'/alibi/track/'+track.album_slug +'/' + stem_track.id"  class="hint-top-middle" :data-hint="$t('global.open_track')">{{ $t('global.track') }}: {{ stem_track.title }}</a>
                                                        (<a target="_blank" :href="'/alibi/track/'+track.album_slug +'/' + stem_track.id"  class="hint-top-middle" :data-hint="$t('global.open_track')">#{{ stem_track.id }}</a>)
                                                    </p>
                                                    <p class="dates_secondary_info">
                                                        <span><strong>{{ $t('track.added') }}:</strong> {{ stem_track.release_date }}</span>
                                                    </p>
                                                </div>
                                                <div class="track-block_bpm">{{ stem_track.bpm }} BPM</div>
                                                <div class="track-block_time">{{ formatAsTime(stem_track.duration) }}</div>
                                                <div class="track-block_download">
                                                    <div class="track-block_ico hint-top-middle" data-hint="Add to Project">
                                                        <template v-if="$auth.check()">
                                                            <i v-on:click="addToProject(track, $event)" class="icon-btn-add-to-proj"></i>
                                                        </template>&nbsp;
                                                    </div>
                                                    <template v-if="$auth.check()">
                                                        <i v-on:click="getFileLink(stem_track)" target="_blank" class="icon-btn-download"></i>
                                                    </template>
                                                    <template v-else>
                                                        <a href="/login" style="opacity:1; position: relative" class="hint-top-right-s-small icon-btn-download" :data-hint="$t('global.login_to_download_a_track')"></a>
                                                    </template>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
				</div>
                <el-dialog
                    :title="formTitle"
                    :visible.sync="formVisible"
                    close-on-click-modal
                    class="projects-dialog">
                    <AlibiTrackForm :initialForm="formData" @addTrackToProjectAction='addTrackToProjectAction' @cancel="formVisible = false" />
                </el-dialog>
				<Footer></Footer>
			</div>
		</div>
	</section>
</template>

<script>
	import Vue from 'vue';
	import axios from 'axios';
    import {mapActions, mapGetters, mapMutations} from 'vuex'

    import AlibiApi from '../api'


	import Footer from "../../../core/components/Footer";
    import ConfirmDialogue from "../../../core/components/ConfirmDialogue";
    import AlibiTrackForm from "./AlibiTrackForm";
	import router from '../../../bootstrap/router';
	import VueCookie from 'vue-cookie';
	import VueSlider from 'vue-slider-component';

	Vue.use(VueCookie)

    export default {
        name: "AlibiAlbum",
        components: {AlibiTrackForm,Footer,VueSlider,ConfirmDialogue},
        data: () => ({
            slug: "",
            album: [],
            showClearListenedButton: false,
            previous_track: null,
            trackForProject: null,
            formVisible: false,
            formData: null,
            formTitle: '',
        }),
        computed: {
            img_section_style: function(){
                if (this.album.tracks === undefined) {
                    return;
                }
                return {
                    "background-image": 'url('+this.album.tracks[0].image_url+')'
                }
            },
        },
        created() {
            for (var i = 0; i<localStorage.length; i++) {
                if (localStorage.key(i).indexOf("track-alibi-") > -1) {
                    this.showClearListenedButton = true;
                }
            }
			if (!this.$auth.check()) {
				window.location = '/login';
			}
			else {
				this.slug = this.$route.params.slug;
				this.fetchData();
				this.enableWatchers = true;
			}
        },

        beforeCreate() {

        },
        mounted() {

        },



        methods: {
            async fetchData() {
                if (!this.$auth.check()) {
                    return;
                }
                $('.loader_bgnd').fadeIn(300);
                const response = await AlibiApi.find(this.slug).then(response => {
                    this.album = response.data;
                    $('.loader_bgnd').fadeOut(300)
                });
            },
            playTrack(trackObj) {
                initialPlayer();
                // Запоминаем, что трек проигран
                localStorage.setItem("track-alibi-"+trackObj.id, 3 * 24 * 60 * Math.floor(Date.now() / 1000));
                this.showClearListenedButton = true;
                // Сделаем сразу прозрачным на 0.7
                if (this.previous_track !== null) {
                    $("#track_block_"+this.previous_track.id).css('opacity', 0.7);
                }
                if ($("#jquery_jplayer_obj").data('play-track-id') == trackObj.id) {
                    if ($("#jquery_jplayer_obj").data('isPlaying')) {
                        $("#jquery_jplayer_obj").jPlayer("pause");
                    }
                    else {
                        $("#jquery_jplayer_obj").jPlayer("play");
                    }
                }
                else {
                    setPlayerDataALIBI(trackObj);
                    $("#jquery_jplayer_obj").jPlayer("setMedia", {
                        mp3: trackObj.stream_url,
                    }).jPlayer("play");
                    this.previous_track = trackObj;
                }
            },
            getVisible(countVal) {
                if (countVal<1) {
                    return "display:none";
                }
                else {
                    return '';
                }
            },
            showStems(e) {
                e.preventDefault();
                e.stopPropagation();

                if ($(e.target.parentNode.parentNode.parentNode).hasClass('open_stems')) {
                    $(e.target.parentNode.parentNode.parentNode).removeClass('open_versions');
                    $(e.target.parentNode.parentNode.parentNode).removeClass('open_stems');
                }
                else {
                    $(e.target.parentNode.parentNode.parentNode).addClass('open_stems');
                    $(e.target.parentNode.parentNode.parentNode).removeClass('open_versions');
                }

            },
            formatAsTime(val) {
                let minutes = Math.floor(val / 60);
                let seconds = Math.floor(val - (minutes * 60));
                if (seconds < 10) {
                    seconds = '0'+seconds;
                }
                return minutes+':'+seconds;
            },

            getFileLink(track) {
                axios.post('/track_download_alibi',
                    {

                            source_audio_id: track.source_audio_id,
                            track_id: track.id,
                            album_num: track.album_id,
                            album_slug: track.album_slug,
                            track_slug: track.slug,
                            album_name: track.album_name,
                            track_name: track.title,
                            track_url: track.stream_url
                        },
                    {
                        headers: {"Authorization" : 'Bearer '+window.localStorage.getItem('auth_token_default')},
                    }
                )
                    .then(response => {

                        if (response.data.block !== null && response.data.block == 1) {
                            alert("This option is disabled");
                            return;
                        }
                        if (response.data.block !== null && response.data.block == 2) {
                            this.showAlibiError();
                            return;
                        }

                        let fileLink = document.createElement('a');
                        fileLink.setAttribute('href', response.data.url);
                        fileLink.setAttribute('download', response.data.fname);
                        document.body.appendChild(fileLink);
                        fileLink.click();
                        document.body.removeChild(fileLink);
                    })
                    .catch(error => console.log(error))
            },

            showAlibiError(err) {
                const ok = this.$refs.confirmDialogue.show({
                    title: this.$t('global.license_for_the_ALIBI_catalog_title'),
                    message: this.$t('global.please_contact_us_alibi'),
                    okButton:'OK'
                });
            },
            isPlackPlayed(trackObj) {
                let isRecord = localStorage.getItem("track-alibi-"+trackObj.id);
                if (isRecord == null) {
                    return;
                }
                if (isRecord < Math.floor(Date.now() / 1000)) {
                    localStorage.removeItem("track-alibi-"+trackObj.id);
                    return;
                }
                return "opacity: 0.6";
            },



			getImgSearchResultInfo() {
				if (this.alibi_tracksMeta.from === undefined) {
					return '';
				}
				return this.$t('global.track') + ' <span>' + (this.alibi_tracksMeta.from + 1) + '</span> - <span>' + this.alibi_tracksMeta.to + '</span>, '+ this.$t('global.results')+': <span>' + this.alibi_tracksMeta.total + '</span>';
			},
			getImgUrl(track) {
				return "background-image:url('" + track.image_url + "')";
			},


            cleanListenedTracks() {
			    let currentTime = Date.now() / 1000;
			    let key = "";
                for (let i = 0; i < localStorage.length; i++) {
                    key = localStorage.key(i);
                    if (key.indexOf("track-") > -1) {
                        if (parseInt(localStorage.getItem(key)) < currentTime) {
                            localStorage.removeItem(key);
                        }
                    }
                }
            },
            removeListenedTracks() {
                let key = "";
                for (let i = 0; i < localStorage.length; i++) {
                    key = localStorage.key(i);
                    if (key.indexOf("rack-") > -1) {
                        localStorage.removeItem(key);
                    }
                }
                this.showClearListenedButton = false;
                $( "div.track_common_block" ).each(function() {
                    $( this ).css('opacity', 1);
                });
            },
            getTrackNumber() {

            },
            addTrackToProjectAction(data) {
                let track = this.trackForProject;
                axios.post('/alibi_add_to_project',
                    {
                        project: data,
                        track: track
                    },
                    {
                        headers: {"Authorization" : 'Bearer '+window.localStorage.getItem('auth_token_default')},
                    }
                )
                    .then(response => {
                    })
                    .catch(error => console.log(error))

                this.formVisible = false
                this.fetchData()
            },
            addToProject(track,eventIcon) {
                this.trackForProject = track;
                this.formTitle = 'Add to Project';
                this.formData = Object.assign({}, {
                    track_id: track.id,
                });
                this.formVisible = true;
            },


        }
    }
</script>
