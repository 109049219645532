<template>
    <section id="alibi-track-form">
        <el-form
            :model="form"
            label-width="120px"
            ref="form"
            size="small"
            @submit.native.prevent="saveSubmit">
            <el-form-item label="Select project" prop="project">
                <el-select
					:value="selected"
					@input="dispatch"
                    @change="errors.clear('project')"
                    suffix-icon="el-icon-edit">
					<el-option
						v-for="item in projectslist"
						:key="item.id"
						:label="item.title"
						:value="item.id">
					  </el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click.native="cancel" size="small">{{$t('global.cancel')}}</el-button>
            <el-button type="success"
                       @click.native="saveSubmit"
                       :loading="formLoading"
                       size="small"
                       class="float-right">{{$t('global.save')}}</el-button>
        </div>
    </section>
</template>

<script>
	import Vue from 'vue';
	import axios from 'axios';
    import {Errors} from "../../../includes/Errors";
    import trackApi from '../api'

    export default {
        name: "AlibiTrackForm",
        props: {
            initialForm: {
                default: () => ({})
            },
        },
        data() {
            return {
				selected: '',
                errors: new Errors(),
                formLoading: false,
                formTitle: 'New Track',
                form: {},
				projectslist: {},
            }
        },
        methods: {
            saveSubmit() {
				let ff = this.form.project;
				this.form.project = '';
				this.selected = '';

				this.$emit('addTrackToProjectAction', {
					project_id: ff,
					track_id: this.form.track_id,
				})
            },
            cancel() {
                this.clearForm()
                this.$emit('cancel')
            },
            clearForm() {
                this.errors.clear()
                if (this.$refs['form'])
                    this.$refs['form'].resetFields()
            },
			dispatch (e) {
				this.form.project = e;
				this.selected = e
			  }
        },
        mounted() {
			axios.get('/get_my_projects',
					{
						headers: {"Authorization" : 'Bearer '+window.localStorage.getItem('auth_token_default')},
					}
					)
					.then(response => {
						this.projectslist = response.data;
					})
					.catch(error => console.log(error));

            this.form = Object.assign({}, this.initialForm)
        },
        watch: {
            initialForm: function(formData) {
                if(_.isEmpty(formData)) this.clearForm()
                this.form = Object.assign({}, formData)
            },
			projectslist: function() {
				// console.log(this.projectslist);
			}
        }
    }
</script>

<style lang="scss" scoped>

</style>
