import ProjectList from "./components/ProjectList";
import ProjectView from "./components/ProjectView";
import ProjectSharedView from "./components/ProjectSharedView";

export const routes = [
    {
        path: '/projects',
        name: 'Projects',
        component: ProjectList,
    },
    {
        path: '/projects/:id',
        name: 'Show Project',
        component: ProjectView,
		hidden: true
    },
	{
		path: '/projects/share/:key/:share',
        name: 'Shared Project',
        component: ProjectSharedView,
		hidden: true
	}
]
