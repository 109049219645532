import * as types from './types'
import {actions} from './actions'

export const store = {
    state: {
        premia: [],
        premiaMeta: [],
        premiaLoading: true,
    },
    getters: {
        premia: state => state.premia,
        premiaMeta: state => state.premiaMeta,
        premiaLoading: state => state.premiaLoading,
    },
    mutations: {
        [types.PREMIUM_OBTAIN](state, premia) {
            state.premia = premia
        },
        [types.PREMIUM_CLEAR](state) {
            state.premia = []
        },
        [types.PREMIUM_SET_LOADING](state, loading) {
            state.premiaLoading = loading
        },
        [types.PREMIUM_META](state, meta) {
            state.premiaMeta = meta
        },
    },
    actions
}
