import * as types from './types'
import {actions} from './actions'

export const store = {
    state: {
        downloads: [],
        downloadsMeta: [],
        downloadsLoading: true,
    },
    getters: {
        downloads: state => state.downloads,
        downloadsMeta: state => state.downloadsMeta,
        downloadsLoading: state => state.downloadsLoading,
    },
    mutations: {
        [types.DOWNLOAD_OBTAIN](state, downloads) {
            state.downloads = downloads
        },
        [types.DOWNLOAD_CLEAR](state) {
            state.downloads = []
        },
        [types.DOWNLOAD_SET_LOADING](state, loading) {
            state.downloadsLoading = loading
        },
        [types.DOWNLOAD_META](state, meta) {
            state.downloadsMeta = meta
        },
    },
    actions
}
