<template>
    <section id="track_show">
        <div class="container-fluid">
			<button v-on:click="copyURL()" class="btn-standart right"><i class="el-icon el-icon-copy-document"></i>{{ $t('global.copy_link') }}</button>
			<h1 style="clear:both">{{ $t('global.track') }} {{ track.primary_title }}</h1>
			<TrackHeader></TrackHeader>
			<TrackBlock :track="track" 
				@addToProject='addToProject'></TrackBlock>
		</div>
		<Footer></Footer>
		<el-dialog
						:title="formTitle"
						:visible.sync="formVisible"
						close-on-click-modal
						class="projects-dialog">
						<TrackForm :initialForm="formData" @addTrackToProjectAction='addTrackToProjectAction' @cancel="formVisible = false" />
					</el-dialog>
    </section>
</template>

<script>
	import axios from 'axios';
    import trackApi from "../api"
	import TrackHeader from "../../../core/components/TrackHeader";
	import TrackBlock from "../../../core/components/TrackBlock";
	import TrackForm from "./TrackForm";
	import Footer from "../../../core/components/Footer";
    export default {
        name: "TrackView",
        components: {TrackForm,TrackBlock,TrackHeader,Footer},
        data() {
            return {
				
                track: {
					label_fk: '',
					album_num: '',
					track_num: ''
					
				},
				formVisible: false,
				formData: null,
				formTitle: ''
            }
        },
        methods: {
            async fetchTrack() {
                const response = await trackApi.find(
					this.$route.params.label,
					this.$route.params.album,
					this.$route.params.track
				)
                this.track = response.data
            },
			copyURL() {
				var dummy = document.createElement('input'),
				text = window.location.href;
				document.body.appendChild(dummy);
				dummy.value = text;
				dummy.select();
				document.execCommand('copy');
				document.body.removeChild(dummy);
			},
			addTrackToProjectAction(data) {
				axios.get('/add_to_project', 
						{ 
							params: data,
							headers: {"Authorization" : 'Bearer '+window.localStorage.getItem('auth_token_default')}, 
						}
					)
					.then(response => {
					})
					.catch(error => console.log(error)) 
			
				this.formVisible = false
			},
			addToProject(track,eventIcon) {
				this.formTitle = 'Add to Project';
                this.formData = Object.assign({}, {
					track_id: track.track_id, 
				});
                this.formVisible = true;
			},
        },
        mounted() {
            this.fetchTrack()
        },
    }
</script>

<style lang="scss" scoped>
	#track_show {
		padding-top: 50px;  
		padding-bottom: 80px;
	}
</style>
