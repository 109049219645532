import * as types from './types'
import {actions} from './actions'

export const store = {
    state: {
        keywords: [],
        keywordsMeta: [],
        keywordsLoading: true,
    },
    getters: {
        keywords: state => state.keywords,
        keywordsMeta: state => state.keywordsMeta,
        keywordsLoading: state => state.keywordsLoading,
    },
    mutations: {
        [types.KEYWORD_OBTAIN](state, keywords) {
            state.keywords = keywords
        },
        [types.KEYWORD_CLEAR](state) {
            state.keywords = []
        },
        [types.KEYWORD_SET_LOADING](state, loading) {
            state.keywordsLoading = loading
        },
        [types.KEYWORD_META](state, meta) {
            state.keywordsMeta = meta
        },
    },
    actions
}
