import AlbumList from "./components/AlbumList";
import AlbumView from "./components/AlbumView";

export const routes = [
    {
        path: '/albums',
        name: 'Albums',
        component: AlbumList,
    },
    {
        path: '/albums/:label/:album',
        name: 'Show Album',
        component: AlbumView,
        hidden: true
    }
]
