<template>
    <section id="dashboard">
        <div class="dashboard_menu">
			<DashboardMenu></DashboardMenu>
		</div>
		<div class="dashboard_main">
			<div class="dashboard_main__container">
				<div class="container-fluid">

					<button class="btn-standart right" @click="downloadProject($event)" ref="downloadBtn"><i class="el-icon el-icon-download"></i> {{ $t('project.download_project') }}</button>


					<template v-if="project.share == ''">
						<button class="btn-standart right" style="margin-right: 10px;" @click="shareProject(1)"><i class="el-icon el-icon-share"></i>{{ $t('project.share_project') }}</button>
					</template>
					<template v-if="project.share !== ''">
						<button class="btn-standart right btn-green" style="margin-right: 10px;" @click="shareProject(0)"><i class="el-icon el-icon-share"></i>{{ $t('project.project_is_shared') }}</button>
					</template>

					<confirm-dialogue ref="confirmDialogue"></confirm-dialogue>

					<h1>{{ $t('global.project') }} "{{ project.title }}"</h1>
					<p>{{ project.description }}</p>

					<div class="tracks-list-header-block">
						<div class="col-icon"></div>
						<div>{{ $t('track.title') }}</div>
						<div class="col-bpm">{{ $t('track.bpm') }}</div>
						<div class="col-time">{{ $t('track.time') }}</div>
						<div class="col-download">&nbsp;&nbsp;&nbsp;</div>

					</div>

					<div>
						<div v-for="track in project.tracks" :key="track.track_id" v-bind:id="'track_block_' + track.track_id" v-bind:data-tid="track.track_id" class="track_common_block">
							<div class="track-block">
								<div class="track-block_icon" v-bind:id="'track_play_btn_' + track.track_id" v-on:click="playTrack(track)" :style="getImgUrl(config.albumCoversServer, track.label_fk, track.album_num)"></div>
								<div class="track-block_title" v-on:click="playTrack(track)">
									<strong>{{ track.primary_title }} <span>{{ track.composers }}</span></strong>
									<p>{{ track.description }}</p>
									<p class="dates_secondary_info">
										<span><strong>{{ $t('track.added') }}:</strong> {{ track.created }}</span>
										<template v-if="track.tempo">
											<span><strong>{{ $t('track.tempo') }}:</strong> {{ track.tempo }}</span>
										</template>
									</p>
								</div>
								<div class="track-block_versions">
									&nbsp;&nbsp;&nbsp;
								</div>
								<div class="track-block_stems">
									&nbsp;&nbsp;&nbsp;
								</div>
								<div class="track-block_bpm">{{ track.bpm }} BPM</div>
								<div class="track-block_time">{{ track.duration }}</div>

								<div class="track-block_ico hint-top-middle" data-hint="Remove from this project">
										<i v-on:click="removeFromProject(track, $event)" class="icon-btn-remove"></i>
								</div>
								<div class="track-block_ico hint-top-middle" :data-hint="$t('global.favorite')">
									<template v-if="$auth.check()">
										<i v-on:click="setFavoriteStatus(track, $event)" v-bind:id="'track_block_favico_' + track.track_id" :class="getFavoriteStatus(track)"></i>
									</template>&nbsp;
								</div>
								<div class="track-block_download">
									<template v-if="$auth.check()">
										<i v-on:click="getFileLink(track.label_fk, track.album_num, track.track_num)" target="_blank" class="icon-btn-download"></i>
									</template>
									<template v-else>
										<a href="/login" style="opacity:1; position: relative" class="hint-top-right-s-small icon-btn-download" :data-hint="$t('global.login_to_download_a_track')"></a>
									</template>
								</div>
							</div>
						</div>


                        <!-- ALIBI -->

                        <br><br>

                        <div v-for="track in project.alibi" :key="track.track_id" v-bind:id="'track_block_' + track.track_id" v-bind:data-tid="track.track_id" class="track_common_block">
                            <div class="track-block">
                                <div class="track-block_icon" v-bind:id="'track_play_btn_' + track.track_id" v-on:click="AlibiPlayTrack(track)" :style="AlibiGetImgUrl(track)"></div>
                                <div class="track-block_title">
                                    <strong>{{ track.track_name }} <span>{{ track.album_name }}</span></strong>
                                    <p>{{ track.description }}</p>
                                    <p class="lab_alb_trck">
                                        <a target="_blank" :href="'/alibi/album/'+track.album_slug" class="hint-top-middle" :data-hint="$t('global.open_album')">{{ $t('global.album') }}: {{ track.album_name }}</a>
                                        <span>/</span>
                                        <a target="_blank" :href="'/alibi/track/'+track.album_slug+'/'+track.track_id"  class="hint-top-middle" :data-hint="$t('global.open_track')">{{ $t('global.track') }}: {{ track.track_name }}</a>
                                        (<a target="_blank" :href="'/alibi/track/'+track.album_slug+'/'+track.track_id"  class="hint-top-middle" :data-hint="$t('global.open_track')">#{{ track.track_id }}</a>)
                                    </p>
                                    <p class="dates_secondary_info">
                                        <span><strong>{{ $t('track.added') }}:</strong> {{ track.release_date }}</span>
                                    </p>
                                </div>
                                <div class="track-block_bpm">{{ track.bpm }} BPM</div>
                                <div class="track-block_time">{{ track.duration }}</div>
                                <div class="track-block_ico hint-top-middle" data-hint="Remove from this project">
                                    <i v-on:click="AlibiRemoveFromProject(track, $event)" class="icon-btn-remove"></i>
                                </div>
                                <div class="track-block_download">
                                    <template v-if="$auth.check()">
                                        <i v-on:click="AlibiGetFileLink(track)" target="_blank" class="icon-btn-download"></i>
                                    </template>
                                    <template v-else>
                                        <a href="/login" style="opacity:1; position: relative" class="hint-top-right-s-small icon-btn-download" :data-hint="$t('global.login_to_download_a_track')"></a>
                                    </template>
                                </div>



                            </div>
                        </div>

                        <!-- // ALIBI -->





					</div>
				</div>
			</div>
		</div>

    </section>
</template>

<script>
	import Vue from 'vue';
	import axios from 'axios';
    import projectApi from "../api"
	import router from '../../../bootstrap/router';
	import DashboardMenu from "./../../dashboard/components/DashboardMenu";
	import VueCookie from 'vue-cookie';
	import ConfirmDialogue from "../../../core/components/ConfirmDialogue";
	Vue.use(VueCookie)

    export default {
        name: "ProjectView",
        components: {DashboardMenu, ConfirmDialogue},
        data() {
            return {
                userName: '',
                project: {},
				lang: Vue.config.lang,
				music_server: config.musicServer,
				album_covers_server: config.albumCoversServer,
                search_url: config.search_url,
                previous_track: null
            }
        },
		created() {

        },
        methods: {
            async fetchProject() {
                const response = await projectApi.find(this.$route.params.id)
                this.project = response.data
            },
			getImgUrl(server,label,album) {
				return "background-image:url('"+server+''+label.toLowerCase()+'/'+album.toLowerCase()+".jpg')";
			},
			removeFromProject(trackObj, iconObj) {
				axios.get('/remove_from_project',
						{
							params: {track_id: trackObj.track_id, project_id: this.project.id},
							headers: {"Authorization" : 'Bearer '+window.localStorage.getItem('auth_token_default')},
						}
					)
					.then(response => {
						this.fetchProject();
					})
					.catch(error => console.log(error));

			},
            AlibiRemoveFromProject(trackObj, iconObj) {
                axios.get('/alibi_remove_from_project',
                    {
                        params: {track_id: trackObj.track_id, project_id: this.project.id},
                        headers: {"Authorization" : 'Bearer '+window.localStorage.getItem('auth_token_default')},
                    }
                )
                    .then(response => {
                        this.fetchProject();
                    })
                    .catch(error => console.log(error));
            },
			getFavoriteStatus(trackObj) {
				let ftc = VueCookie.get('favorite_tracks');
				let favorite_tracks = [];
				if (ftc!==undefined && ftc!==null && ftc!=="") {
					favorite_tracks = ftc.split(',');
				}

				if (favorite_tracks.indexOf(trackObj.track_id+"")>-1) {
					return 'icon-btn-favorite-active';
				}
				else {
					return 'icon-btn-favorite';
				}
			},
			setFavoriteStatus(trackObj, iconObj) {
				iconObj.currentTarget.className = "icon-btn-favorite-changing";
				axios.get('/user_like_track',
						{
							params: {track_id: trackObj.track_id},
							headers: {"Authorization" : 'Bearer '+window.localStorage.getItem('auth_token_default')},
						}
					)
					.then(response => {
						this.$cookie.set('favorite_tracks', response.data.favorite_tracks);
						$("#track_block_favico_"+response.data.last_change_id).attr('class', this.getFavoriteStatus(trackObj));
					})
					.catch(error => console.log(error))

			},
			playTrack(trackObj) {
				initialPlayer();
				let label = trackObj.label_fk;
				let album = trackObj.album_num;
				let track = trackObj.track_num;

				if ($("#jquery_jplayer_obj").data('play-track-id') == trackObj.track_id) {
					if ($("#jquery_jplayer_obj").data('isPlaying')) {
						$("#jquery_jplayer_obj").jPlayer("pause");
					}
					else {
						$("#jquery_jplayer_obj").jPlayer("play");
					}
				}
				else {
					setPlayerData(trackObj, this.album_covers_server);
					$("#jquery_jplayer_obj").jPlayer("setMedia", {
						mp3: this.music_server + 'stream_music_file.php?label='+label.toLowerCase()+'&album='+album.toLowerCase()+'&track='+track,
					}).jPlayer("play");
				}
			},
			getFileLink(label, album, track) {
				axios.get('/track_download',
						{
							params: {
								l: label.toLowerCase(),
								a: album.toLowerCase(),
								t: track
							},
							headers: {"Authorization" : 'Bearer '+window.localStorage.getItem('auth_token_default')},
						}
					)
					.then(response => {
						if (response.data.block !== null && response.data.block) {
							alert("This option is disabled");
							return;
						}
						var fileLink = document.createElement('a');
						fileLink.href = response.data.url;
						fileLink.setAttribute('download', response.data.fname+'.mp3');
						document.body.appendChild(fileLink);
						fileLink.click();
					  })
					  .catch(error => console.log(error))


			},
            AlibiGetFileLink(track) {
                axios.post('/track_download_alibi',
                    {
                        source_audio_id: track.source_audio_id,
                        track_id: track.track_id,
                        album_num: track.album_num,
                        album_slug: track.album_slug,
                        track_slug: track.track_slug,
                        album_name: track.album_name,
                        track_name: track.track_name,
                        track_url: track.track_url
                    },
                    {
                        headers: {"Authorization" : 'Bearer '+window.localStorage.getItem('auth_token_default')},
                    }
                )
                    .then(response => {

                        if (response.data.block !== null && response.data.block == 1) {
                            alert("This option is disabled");
                            return;
                        }
                        if (response.data.block !== null && response.data.block == 2) {
                            this.showAlibiError();
                            return;
                        }

                        let fileLink = document.createElement('a');
                        fileLink.setAttribute('href', response.data.url);
                        fileLink.setAttribute('download', response.data.fname);
                        document.body.appendChild(fileLink);
                        fileLink.click();
                        document.body.removeChild(fileLink);
                    })
                    .catch(error => console.log(error))
            },
			resetDownloadButton() {
				this.$refs.downloadBtn.innerHTML = '<i class="el-icon el-icon-download"></i> '+this.$t('project.download_project');
			},
			downloadProject(buttonObj) {
				buttonObj.currentTarget.innerHTML = '<i class="el-icon el-icon-download"></i> '+this.$t('global.loading');
				axios.get('/project_download',
						{
							params: {
								project: this.project.id,
							},
							headers: {"Authorization" : 'Bearer '+window.localStorage.getItem('auth_token_default')},
						}
					)
					.then(response => {
						if (response.data.block !== null && response.data.block) {
							alert("This option is disabled");
							return;
						}
						if (response.data.error !== undefined) {
							this.showError(response.data.error);
						}
						else {
							if (response.data.block !== null && response.data.block) {
								alert("This option is disabled");
								return;
							}
							this.resetDownloadButton();
							var fileLink = document.createElement('a');
							fileLink.href = response.data;
							fileLink.setAttribute('download', response.data);
							document.body.appendChild(fileLink);
							fileLink.click();
						}
					  })
					  .catch(error => console.log(error))
			},
			shareProject(action) {
				if (action == 1) {
					axios.get('/project_share',
							{
								params: {
									project: this.project.id,
									share: 1
								},
								headers: {"Authorization" : 'Bearer '+window.localStorage.getItem('auth_token_default')},
							}
						)
						.then(response => {
							// console.log(response.data);
							this.showShareWindow(response.data);
						})
						.catch(error => console.log(error));
				}
				else {
					axios.get('/project_share_info',
							{
								params: {
									project: this.project.id,
								},
								headers: {"Authorization" : 'Bearer '+window.localStorage.getItem('auth_token_default')},
							}
						)
						.then(response => {
							this.showShareWindowInfo(response.data);
						})
						.catch(error => console.log(error));
				}
			},

			async showShareWindowInfo(data) {
				const ok = await this.$refs.confirmDialogue.show({
					title: this.$t('project.share_project'),
					message: this.$t('project.cancel_sharing_text') + '<br><br>'
					+'<input type="text" id="share_proj_link" style="border: 1px solid #ccc; min-width: 300px; border-radius:4px" value="'+this.search_url+'projects/share/'+data.share+'/'+data.protection+'">'
					+ '<br>'
					+ '<br>'
					+ '<button class="el-button el-button--info " onclick="copyLink(\'share_proj_link\')">'+ this.$t('project.copy_link') +' <i class="el-icon el-icon-copy-document"></i></button>',

					okButton: this.$t('project.cancel_sharing'),
				});
				if (ok) {
					axios.get('/project_share',
							{
								params: {
									project: this.project.id,
									share: 0
								},
								headers: {"Authorization" : 'Bearer '+window.localStorage.getItem('auth_token_default')},
							}
						)
						.then(response => {
							this.fetchProject();
						})
						.catch(error => console.log(error));
				}

			},
            showAlibiError(err) {
                const ok = this.$refs.confirmDialogue.show({
                    title: this.$t('global.license_for_the_ALIBI_catalog_title'),
                    message: this.$t('global.please_contact_us_alibi'),
                    okButton:'OK'
                });
            },
			unshareProject() {

			},

			showShareWindow(data) {
				const ok = this.$refs.confirmDialogue.show({
					title: this.$t('project.share_project'),
					message: '<input type="text" id="share_proj_link" style="border: 1px solid #ccc; min-width: 300px; border-radius:4px" value="'+this.search_url+'projects/share/'+data.share+'/'+data.protection+'">'
					+ '<br>'
					+ '<br>'
					+ '<button class="el-button el-button--info " onclick="copyLink(\'share_proj_link\')">'+ this.$t('project.copy_link') +' <i class="el-icon el-icon-copy-document"></i></button>',

					okButton: 'Ok',
				});
				if (ok || !ok) {
					this.fetchProject();
				}
			},

			showError(err) {
				const ok = this.$refs.confirmDialogue.show({
					title: this.$t('global.error'),
					message: this.$t('project.errors.'+err),
				});
			},
            AlibiPlayTrack(trackObj) {
                trackObj['id'] = trackObj.track_id;
                trackObj['image_url'] = trackObj.image;
                // console.log(trackObj);
                // return;
                trackObj.id = trackObj.track_id;
                initialPlayer();
                // Запоминаем, что трек проигран
                localStorage.setItem("track-alibi-"+trackObj.id, 3 * 24 * 60 * Math.floor(Date.now() / 1000));
                this.showClearListenedButton = true;
                // Сделаем сразу прозрачным на 0.7
                if (this.previous_track) {
                    $("#track_block_"+this.previous_track.id).css('opacity', 0.7);
                }
                if ($("#jquery_jplayer_obj").data('play-track-id') == trackObj.id) {
                    if ($("#jquery_jplayer_obj").data('isPlaying')) {
                        $("#jquery_jplayer_obj").jPlayer("pause");
                    }
                    else {
                        $("#jquery_jplayer_obj").jPlayer("play");
                    }
                }
                else {
                    setPlayerDataALIBI(trackObj);
                    $("#jquery_jplayer_obj").jPlayer("setMedia", {
                        mp3: trackObj.track_url,
                    }).jPlayer("play");
                    this.previous_track = trackObj;
                }
            },
            AlibiGetImgUrl(track) {
                return "background-image:url('" + track.image + "')";
            },
        },
        mounted() {
            this.fetchProject()
        },
    }
</script>

<style lang="scss" scoped>
#dashboard {
		margin-top: 28px;
		min-height: calc(100vh - 53px);
		display: flex;

	}
	.dates_secondary_info {
		padding-top: 4px;
		border-top: 1px solid rgba(255,255,255,0.2);
		span {
			display: inline-block;
			padding-right: 4px;
			strong {
				display: inline-block;
			}
			&::after {
				display: inline-block;
				padding-left: 8px;
				content: "|";
				opacity: 0.3;
			}
			&:last-child {
				&::after {
					display:none;
				}
			}
		}
	}
	.lab_alb_trck span {
		opacity: 0.4;
		position: relative;
	}
	.lab_alb_trck a {
		position: relative;
		border-radius: 4px;
		padding: 0 3px;
		&:hover {
			background:#000;
		}
	}

</style>
