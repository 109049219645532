import * as types from './types'
import sfxApi from '../api'

export const actions = {
    async [types.SFX_FETCH]({commit}, data = null) {
        commit(types.SFX_SET_LOADING, true)
        const response = await sfxApi.all(data)
        commit(types.SFX_OBTAIN, response.data.data)
		commit(types.SFX_SAMPLE, response.data.sample)
        commit(types.SFX_META, response.data.meta)
        commit(types.SFX_SET_LOADING, false)
    },
}
