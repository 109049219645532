import axios from 'axios'

const API_ENDPOINT = 'albums'

export default {

    all(data) {
        return axios.get(API_ENDPOINT, {params: data})
    },

    find(label,album) {
        return axios.get(API_ENDPOINT + '/' + label + '/' + album)
    },


}
