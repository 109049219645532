import * as types from './types'
import {actions} from './actions'

export const store = {
    state: {
        albums: [],
        albumsMeta: [],
        albumsLoading: true,
    },
    getters: {
        albums: state => state.albums,
        albumsMeta: state => state.albumsMeta,
        albumsLoading: state => state.albumsLoading,
    },
    mutations: {
        [types.ALBUM_OBTAIN](state, albums) {
            state.albums = albums
        },
        [types.ALBUM_CLEAR](state) {
            state.albums = []
        },
        [types.ALBUM_SET_LOADING](state, loading) {
            state.albumsLoading = loading
        },
        [types.ALBUM_META](state, meta) {
            state.albumsMeta = meta
        },
    },
    actions
}
