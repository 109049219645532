<template>
    <div class="tracks_active_filters">
		
		<div :class="getCSSClassSimilar()">
			<strong>{{ $t('filter.similar_track') }}:</strong>
			<div class="label_item hint-top-left">
				{{ getSimilarTrackInfo() }}<button v-on:click="removeSimilar()"></button>
			</div>
		</div>
		
	</div>
</template>

<script>
	import Vue from 'vue';
    import axios from 'axios';
	
    export default {
		props: ['sfx_sample'],
		name: "SfxFilters",
		data() {
			return {
			};
		},
		beforeCreate() {
			
		},
		
		watch:{
			
        },
		mounted() {

		},
		methods: {
			
			removeSimilar () {
				this.$emit('removeSimilar')
			},
			
			getSimilarTrackInfo() {
				if (this.sfx_sample !== undefined) {
					return this.sfx_sample.label_fk + " #"+ this.sfx_sample.album_num + " #"+this.sfx_sample.track_num + " - " + this.sfx_sample.primary_title;
				}
				
			},
			
			getCSSClassSimilar() {
				if (this.sfx_sample !==undefined && this.sfx_sample.track_id !== undefined) {
					return "active_filter_track filter_similar";
				}
				else {
					return "filter_hidden";
				}
			},
			
			
		}
    }
</script>

<style lang="scss" scoped>
	.tracks_active_filters {
		border-top: 1px solid rgba(255,255,255,0.11);
		padding-top: 8px;
		text-align: left;
	}
	.filter_hidden {
		display:none;
	}
	.active_filter_track {
		
	}
	
	
	.active_filter_track {
		display: inline-block;
		margin: auto;
		position: relative;
		vertical-align:middle;
		&::before {
			content: "";
			display: inline-block;
			height: 20px; 
			width: 20px; 
			vertical-align:middle;
		}
		strong {
			vertical-align:middle;
			display: inline-block;
			padding: 4px 9px;
			font-weight: 200;
			opacity: 0.7;
		}
		.label_item {
			vertical-align:middle;
			padding: 4px 6px 4px 9px;
			background: #000;
			border-radius: 5px;
			position: relative;
			display: inline-block;
			margin-right: 4px;
			border: 1px solid rgba(255,255,255,0.2);
			button {
				display: inline-block;
				vertical-align:middle;
				border: 0;
				height: 14px;
				width: 15px;
				background: transparent;
				margin-left: 5px;
				position:relative;
				&::after,
				&::before {
					left: 0px;
					top: 5px;
					content:"";
					display:block;
					height: 1px;
					width: 13px;
					position: absolute;
					background: rgba(255,255,255,0.6);
					transform: rotate(45deg);
					transition: all 200ms ease;
				}
				&::before {
					transform: rotate(-45deg);
				}
			}
			&:hover {
				button {
					&::after,
					&::before {
						background: rgba(255,255,255,1);
					}
				}
			}
		}
		.label_item_exclde {
			background: #730000;
			em {
				font-style: normal;
				font-weight: bold;
			}
		}
	}
	
	.filter_labels {
		&::before {
			background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAAmVBMVEUAAAD////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////VHQRUAAAAMnRSTlMA7fMR4Avovrkk5Mm2rIhZMh8P+dzY0sN9eGpELhUHzcaymHJkXlA6KhgCoZ6CKI+NSkl/N3UAAAD1SURBVBjTdc9HbsNADAXQP6NR773Z6t0lCe9/uEASbK/8NiTIDwLEQS/aiAmr1/Em9wqxxrYEsUzCaW0pKjx/csuxJs0/czbdVqBk3Og8h7Qjm9NtAyC5TkpidugBwDeiK07bwMTFZE+goAK7Sq+Age4T5YDFLvvMM8N6BlSljFMgMqU9Z3KVJzLycLQVgGkVAJ002ORhCt07B0SzJ+WE2ywOEPytmgJYynFzTsK43BtfNEBPw/mDFxzVpQzQlVjCR1CzBUBGHT5+yNn2nUXd6yfpl9Sz9zVKxkWG/HRNSvXX/sHJUFtVEHeueFty1eBGml3w3T/sThP6R/3XBwAAAABJRU5ErkJggg==') 0 0 no-repeat;
		}
	}
	.filter_eras {
		&::before {
			background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAAgVBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////9d3yJTAAAAKnRSTlMA3+cQte3FFQW5LykLAr59eGsk841yRsmsoJeIVB8Z2KaCZTUI0s1eWSBIxBfbAAAA3ElEQVQY03WOR3LDMBRDQYqkCtWL1Sy5puH+B0xIxk42fpuPATB/AE/bKKGFGlo8yQdJbSYlqKsIgV1RNFm8J/HhnekWehPnHYHkytR3a84FgCNv8OcCYJMiDtkBjlRboGGD/6ZlDSidwVH9mtHJAGIMK3oe/S0mCej07iQWnm+JGzB3gDCRk4gNx6YFkEpASf/zXnwNgv1PLAww8IpAnvVnIGMFtPIU4WEXSEpt/ZYFf6xcCzdMcYkRiHqWQW8p3z5sjtx+jjTtI790lKUqBbs1xhNbl7KTpsrwmm+q6A6ZYvYCuAAAAABJRU5ErkJggg==') 0 0 no-repeat;
		}
	}
	.filter_similar {
		&::before {
			background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAAdVBMVEUAAAD////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////li2ZAAAAAJnRSTlMA8u/di0QoIAP44aqldzYGfToN9Ouhm5NxQD4S2Mu5riUbj21KFpZuyrgAAACxSURBVBjTtY0JDoJADEV/hwGGfRUQlcXl3/+IFjBewPiSvnQmvy1+Zzx5xkvPLhU1DizJRsuuN3Xvjr+oDC7L0NC6IBP2ACqKjuSTnwsrfRr6wIklgI4WGVNAnQAeA8Bd1SHjAgijGDDNBajb6IVaZGuN+SSLPRl9kykzHJvLbRsGzsBIUwE5JfenUZt2u447JVucpTzCOhwMOyiuJ3lbrbrV6grs+IknSaE28fzEn3gDHroOIx9M2FcAAAAASUVORK5CYII=') 0 0 no-repeat;
		}
	}
	.filter_tempo {
		&::before {
			background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAAk1BMVEUAAAD////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////ROyVeAAAAMHRSTlMA5Uf77eoeBsyEQhSZdScQAtnUo5J7PQvy3MC8sWYj+MSrjV9ZTTQY0L63j3FQNjEhkSd0AAAA4klEQVQY01XO6W6DMBAE4AFfYO4rBEhJmqZN7+77P129Nkjt92NnZUv2gD0lSsopNWDX2ceNRNu2knI4X7SAJbJ0s0qEBrSM7nAy0aHI+7GgGPWbG+xAD0jEsbQ0IKUUXkEvWO03NHWWZgPvdBxNS+IHjVJKwzPR7EqlQ4WO6BlB6Z6p3Ud35G7b3Ny1cR+tB7Fg15MGTDZmkSorbJKp9vlOcf8aVmTnzudAV3zQir16iItBHPZQHciU1G6Kx6265FktluOiRrCoiZm1PE9UgjX011mDff473N6shybaTXlo/wvgGxUVjSgdywAAAABJRU5ErkJggg==') 0 0 no-repeat;
		}
	}
	

	
</style>
