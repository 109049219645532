<template>
	<div class="tracks-list-header-block">
								<div class="col-icon"></div>
								<div>{{ $t('track.title') }}</div>
								<div class="col-bpm">{{ $t('track.bpm') }}</div>
								<div class="col-time">{{ $t('track.time') }}</div>
								<div class="col-download">&nbsp;</div>
	</div>
</template>

<script>
    export default {
        name: "TrackHeader",
        data: () => ({
        }),
    }
</script>