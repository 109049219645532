import { render, staticRenderFns } from "./DashboardMenu.vue?vue&type=template&id=327802e9&scoped=true&"
import script from "./DashboardMenu.vue?vue&type=script&lang=js&"
export * from "./DashboardMenu.vue?vue&type=script&lang=js&"
import style0 from "./DashboardMenu.vue?vue&type=style&index=0&id=327802e9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "327802e9",
  null
  
)

export default component.exports