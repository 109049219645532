<template>
    <div class="order-block">
		<em>{{ $t('sort.sort') }}</em>
		<ul>
			<li :class="showActiveSort('relevance')" style="cursor: pointer" @click="setOrder('relevance', '')"><span>{{ $t('sort.relevance') }}</span>
			</li>

			<li :class="showActiveSort('title.keyword')"><span>{{ $t('sort.title') }}</span>
				<ul>
					<li @click="setOrder('title.keyword', 'desc')">{{ $t('sort.high_to_low') }}</li>
					<li @click="setOrder('title.keyword', 'asc')">{{ $t('sort.low_to_high') }}</li>
				</ul>
			</li>

			<li :class="showActiveSort('album_title.keyword')"><span>{{ $t('sort.album_title') }}</span>
				<ul>
					<li @click="setOrder('album_title.keyword', 'desc')">{{ $t('sort.high_to_low') }}</li>
					<li @click="setOrder('album_title.keyword', 'asc')">{{ $t('sort.low_to_high') }}</li>
				</ul>
			</li>

            <li :class="showActiveSort('bpm')"><span>{{ $t('sort.bpm') }}</span>
                <ul>
                    <li @click="setOrder('bpm', 'desc')">{{ $t('sort.high_to_low') }}</li>
                    <li @click="setOrder('bpm', 'asc')">{{ $t('sort.low_to_high') }}</li>
                </ul>
            </li>

            <li :class="showActiveSort('duration')"><span>{{ $t('sort.length') }}</span>
                <ul>
                    <li @click="setOrder('duration', 'desc')">{{ $t('sort.high_to_low') }}</li>
                    <li @click="setOrder('duration', 'asc')">{{ $t('sort.low_to_high') }}</li>
                </ul>
            </li>

            <li :class="showActiveSort('release_date')"><span>{{ $t('sort.published') }}</span>
                <ul>
                    <li @click="setOrder('release_date', 'desc')">{{ $t('sort.high_to_low') }}</li>
                    <li @click="setOrder('release_date', 'asc')">{{ $t('sort.low_to_high') }}</li>
                </ul>
            </li>

		</ul>
	</div>
</template>

<script>

    import axios from 'axios';
    export default {
		data: () => ({
            order: 'relevance',
            order_dir: 'relevance',
        }),
		mounted() {
		},
		methods: {
			showActiveSort(field) {
				if (this.$route.query.order === "" || this.$route.query.order === undefined) {
					var order = this.order;
					var order_dir = this.order_dir;
				}
				else {
					var order = this.$route.query.order;
					var order_dir = this.$route.query.order_dir;
				}
				if (order == field) {
					if (field == 'relevance') {
						return 'active_order_relevance';
					}
					return 'active_order_'+order_dir;
				}
			},
			setOrder(order, order_dir) {
				this.$emit('setOrder', {
                    order: order,
                    order_dir: order_dir,
				})
			},
		}
    }
</script>
