<template>
    <popup-modal ref="popup">
        <h2>{{ title }}</h2>
        
		 <p v-html="message"></p>
        <div class="popup_btns">
			<template v-if="cancelButton !== ''">
				<button class="el-button el-button--info " @click="_cancel">{{ cancelButton }}</button>
			</template>
			
            <template v-if="okButton !== ''">
				<button class="el-button" @click="_confirm">{{ okButton }}</button>
			</template>
        </div>
    </popup-modal>
</template>

<script>
import PopupModal from './PopupModal.vue'

export default {
    name: 'ConfirmDialogue',

    components: { PopupModal },

    data: () => ({
        // Parameters that change depending on the type of dialogue
        title: undefined,
        message: undefined, // Main text content
        okButton: 'Yes', // Text for confirm button; leave it empty because we don't know what we're using it for
        cancelButton: 'Cancel', // text for cancel button
        
        // Private variables
        resolvePromise: undefined,
        rejectPromise: undefined,
    }),

    methods: {
        show(opts = {}) {
            this.title = opts.title
            this.message = opts.message
            
			if (opts.okButton!== undefined) {
				this.okButton = opts.okButton;
			}
			else {
				this.okButton = this.$t('global.yes');
			}
			
            if (opts.cancelButton !== undefined) {
                this.cancelButton = opts.cancelButton
            }
			else {
				this.cancelButton = this.$t('global.cancel');
			}
			
            this.$refs.popup.open()
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                this.resolvePromise = resolve
                this.rejectPromise = reject
            })
        },

        _confirm() {
            this.$refs.popup.close()
            this.resolvePromise(true)
        },

        _cancel() {
            this.$refs.popup.close()
            this.resolvePromise(false)
        },
    },
}
</script>

<style lang="scss" scoped>
.popup_btns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
</style>
