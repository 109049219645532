import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from './../includes/lang'
import axios from 'axios'
import {changeMomentLocale} from "./moment";
import VueCookie from 'vue-cookie'
Vue.use(VueCookie)

Vue.config.lang = VueCookie.get('locale') || 'en';

Vue.use(VueI18n)

const DEFAULT_LANGUAGE = Vue.config.lang;

changeMomentLocale(DEFAULT_LANGUAGE)

const i18n = new VueI18n({
    locale: DEFAULT_LANGUAGE,
    messages,
    silentTranslationWarn: true
})

setI18nLanguage(DEFAULT_LANGUAGE, i18n)

function setI18nLanguage (lang, i18n) {
    i18n.locale = lang
    axios.defaults.headers.common['Accept-Language'] = lang
    document.querySelector('html').setAttribute('lang', lang)
    return lang
}

export default i18n
