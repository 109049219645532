import AlibiList from "./components/AlibiList";
import AlibiAlbum from "./components/AlibiAlbum";
import AlibiTrack from "./components/AlibiTrack";

export const routes = [
    {
        path: '/alibi',
        name: 'ALIBI',
        component: AlibiList,
    },
    {
        path: '/alibi/album/:slug',
        name: 'Show Alibi Album',
        component: AlibiAlbum,
        hidden: true
    },
    {
        path: '/alibi/track/:slug/:track_id',
        name: 'Show Alibi Track',
        component: AlibiTrack,
        hidden: true
    }
]
