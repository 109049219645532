<template>
		<div class="search-field-root albums-search-root">
		
		<div class="search-select-container albums-search-label">
			<selectize v-model="selected_label" :settings="settings" :placeholder="$t('global.select_label')"> <!-- settings is optional -->
				<option value="">{{ $t('global.select_label') }}</option>
				<option v-for="label in labels"
								:value="label.label"
								:title="label.publisher"
								:selected="label.label == $route.query.label">{{ label.label }} - {{ shortPublisher(label.publisher) }}</option>
			</selectize>
		</div>
		
		
		
		<div class="search-field-container albums-search-text">
			<div class="search-field-container_input">
				<form v-on:submit.prevent="setSearchText">
					<input type="text" :placeholder="$t('global.find_the_album')" id="search-text" :value="$route.query.search">
					<button v-on:click="setSearchText" class="search-submit-btn"></button>
				</form>
			</div>
		</div>
		
		 
		
	</div>
</template>

<script>
		import Selectize from 'vue2-selectize';
		import axios from 'axios';
		export default {
			components: {
				Selectize
			},
		data() {
			return {
				settings: {persist: false},
				selected_label: 1,
				labels: null
			};
		},
		mounted() {
			axios
			.get('labels')
			.then(response => (this.labels = response.data));
			if(this.$route.query.label!==undefined && this.$route.query.label!=="") {
				this.selected_label = this.$route.query.label;
			}
		},
		watch:{
			selected_label: function () {
				this.$emit('setLabel', {
					label: this.selected_label,
				});
				},
		},
		methods: {
			shortPublisher(title) {
				return title.length > 21 ? title.substring(0, 21) + '...' : title;
			},
			setSearchText(event) {
				this.$emit('setSearchText', {
					text: $("#search-text").val(),
				})
			}
		}
		}
</script>

<style scoped type="scss">
	
</style>
