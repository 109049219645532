import PlaylistList from "./components/PlaylistList";
import PlaylistView from "./components/PlaylistView";

export const routes = [
    {
        path: '/playlists',
        name: 'Playlists',
        component: PlaylistList,
    },
    {
        path: '/playlists/:id',
        name: 'Show Playlist',
        component: PlaylistView,
        hidden: true
    }
]
