<template>
	<section id="track" class="track_search_list_container">
        <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>

		<div class="track_list_filter_block" style="height:calc(100vh - 145px)">
				<div class="track_list_filter_block_main">
					<ul>
						<li ref="filters_main_genres"><a v-on:click.prevent="showFilter('genres', false)">{{ $t('filter.genre') }}</a></li>
                        <li ref="filters_main_moods"><a v-on:click.prevent="showFilter('moods', false)">{{ $t('filter.mood') }}</a></li>
                        <li ref="filters_main_instruments"><a v-on:click.prevent="showFilter('instruments', false)">{{ $t('filter.instruments') }}</a></li>
                        <li ref="filters_main_topic"><a v-on:click.prevent="showFilter('topic', false)">{{ $t('filter.topic') }}</a></li>
                        <li ref="filters_main_performance"><a v-on:click.prevent="showFilter('performance', false)">{{ $t('filter.performance') }}</a></li>
                        <li ref="filters_main_advanced"><a v-on:click.prevent="showFilter('advanced', false)">{{ $t('filter.advanced') }}</a></li>
                        <li ref="filters_main_era"><a v-on:click.prevent="showFilter('era', false)">{{ $t('filter.era') }}</a></li>
					</ul>
				</div>
				<div class="track_list_filter_block_sub">
					<div class="track_list_filter_block_sub_cnt" style="height: calc(100vh - 145px); ">

						<div class="track_list_filter_genres_list">
							<ul>
								<li v-for="(subTree, keyword) in keywords.genres"><div v-on:click.stop="addKeyword(keyword)">
										<i class="add_word hint-top-right" :data-hint="$t('filter.include')" v-on:click.stop="addKeyword(keyword)"></i>
										<i class="exclude_word hint-top-right" :data-hint="$t('filter.exclude')" v-on:click.stop="excludeKeyword(keyword)"></i>
										<span v-if="notEmptyTree(subTree)" v-on:click.stop="openLevel"></span>
										<p>{{ keyword }}</p>
									</div>
									<ul v-if="subTree">
										<li v-for="(subTree2, keyword2) in subTree" v-on:click.stop="addKeyword(keyword2)"><div>
												<i class="add_word hint-top-right" :data-hint="$t('filter.include')" v-on:click.stop="addKeyword(keyword2)"></i>
												<i class="exclude_word hint-top-right" :data-hint="$t('filter.exclude')" v-on:click.stop="excludeKeyword(keyword2)"></i>
												<p>{{ keyword2 }}</p>
											</div>
										</li>
									</ul>
								</li>
							</ul>
						</div>

                        <div class="track_list_filter_moods_list">
                            <ul>
                                <li v-for="(subTree, keyword) in keywords.moods"><div v-on:click.stop="addKeyword(keyword)">
                                    <i class="add_word hint-top-right" :data-hint="$t('filter.include')" v-on:click.stop="addKeyword(keyword)"></i>
                                    <i class="exclude_word hint-top-right" :data-hint="$t('filter.exclude')" v-on:click.stop="excludeKeyword(keyword)"></i>
                                    <span v-if="notEmptyTree(subTree)" v-on:click.stop="openLevel"></span>
                                    <p>{{ keyword }}</p>
                                </div>
                                    <ul v-if="subTree">
                                        <li v-for="(subTree2, keyword2) in subTree" v-on:click.stop="addKeyword(keyword2)"><div>
                                            <i class="add_word hint-top-right" :data-hint="$t('filter.include')" v-on:click.stop="addKeyword(keyword2)"></i>
                                            <i class="exclude_word hint-top-right" :data-hint="$t('filter.exclude')" v-on:click.stop="excludeKeyword(keyword2)"></i>
                                            <p>{{ keyword2 }}</p>
                                        </div>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>

                        <div class="track_list_filter_instruments_list">
                            <ul>
                                <li v-for="(subTree, keyword) in keywords.instruments"><div v-on:click.stop="addKeyword(keyword)">
                                    <i class="add_word hint-top-right" :data-hint="$t('filter.include')" v-on:click.stop="addKeyword(keyword)"></i>
                                    <i class="exclude_word hint-top-right" :data-hint="$t('filter.exclude')" v-on:click.stop="excludeKeyword(keyword)"></i>
                                    <span v-if="notEmptyTree(subTree)" v-on:click.stop="openLevel"></span>
                                    <p>{{ keyword }}</p>
                                </div>
                                    <ul v-if="subTree">
                                        <li v-for="(subTree2, keyword2) in subTree" v-on:click.stop="addKeyword(keyword2)"><div>
                                            <i class="add_word hint-top-right" :data-hint="$t('filter.include')" v-on:click.stop="addKeyword(keyword2)"></i>
                                            <i class="exclude_word hint-top-right" :data-hint="$t('filter.exclude')" v-on:click.stop="excludeKeyword(keyword2)"></i>
                                            <p>{{ keyword2 }}</p>
                                        </div>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>

                        <div class="track_list_filter_topic_list">
                            <ul>
                                <li v-for="(subTree, keyword) in keywords.topic"><div v-on:click.stop="addKeyword(keyword)">
                                    <i class="add_word hint-top-right" :data-hint="$t('filter.include')" v-on:click.stop="addKeyword(keyword)"></i>
                                    <i class="exclude_word hint-top-right" :data-hint="$t('filter.exclude')" v-on:click.stop="excludeKeyword(keyword)"></i>
                                    <span v-if="notEmptyTree(subTree)" v-on:click.stop="openLevel"></span>
                                    <p>{{ keyword }}</p>
                                </div>
                                    <ul v-if="subTree">
                                        <li v-for="(subTree2, keyword2) in subTree" v-on:click.stop="addKeyword(keyword2)"><div>
                                            <i class="add_word hint-top-right" :data-hint="$t('filter.include')" v-on:click.stop="addKeyword(keyword2)"></i>
                                            <i class="exclude_word hint-top-right" :data-hint="$t('filter.exclude')" v-on:click.stop="excludeKeyword(keyword2)"></i>
                                            <p>{{ keyword2 }}</p>
                                        </div>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>

                        <div class="track_list_filter_performance_list">
                            <ul>
                                <li v-for="(subTree, keyword) in keywords.performance"><div v-on:click.stop="addKeyword(keyword)">
                                    <i class="add_word hint-top-right" :data-hint="$t('filter.include')" v-on:click.stop="addKeyword(keyword)"></i>
                                    <i class="exclude_word hint-top-right" :data-hint="$t('filter.exclude')" v-on:click.stop="excludeKeyword(keyword)"></i>
                                    <span v-if="notEmptyTree(subTree)" v-on:click.stop="openLevel"></span>
                                    <p>{{ keyword }}</p>
                                </div>
                                    <ul v-if="subTree">
                                        <li v-for="(subTree2, keyword2) in subTree" v-on:click.stop="addKeyword(keyword2)"><div>
                                            <i class="add_word hint-top-right" :data-hint="$t('filter.include')" v-on:click.stop="addKeyword(keyword2)"></i>
                                            <i class="exclude_word hint-top-right" :data-hint="$t('filter.exclude')" v-on:click.stop="excludeKeyword(keyword2)"></i>
                                            <p>{{ keyword2 }}</p>
                                        </div>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>

                        <div class="track_list_filter_advanced_list">
                            <div class="filter_advanced_list__container">
                                <p class="filter_advanced_list__container-title">BPM <span>({{ bpm[0] }} - {{ bpm[1] }})</span></p>
                                <div class="filter_advanced_list__container-slider">
                                    <vue-slider v-model="bpm" :lazy="true" :max="200"></vue-slider>
                                </div>
                                <br><br>
                                <p class="filter_advanced_list__container-title">{{ $t('filter.tempo') }}</p>

                            </div>
                            <ul>
                                <li :class="isActiveOption('very_fast', filters.tempo, ',')"><div v-on:click.stop="switchTempo('very_fast')" :data-hint="$t('filter.find_with_this_tempo')" class="hint-top-middle">
                                    <p>{{ $t('filter.tempos.very_fast') }}</p>
                                </div>
                                </li>
                                <li :class="isActiveOption('fast', filters.tempo, ',')"><div v-on:click.stop="switchTempo('fast')" :data-hint="$t('filter.find_with_this_tempo')" class="hint-top-middle">
                                    <p>{{ $t('filter.tempos.fast') }}</p>
                                </div>
                                </li>
                                <li :class="isActiveOption('medium_fast', filters.tempo, ',')"><div v-on:click.stop="switchTempo('medium_fast')" :data-hint="$t('filter.find_with_this_tempo')" class="hint-top-middle">
                                    <p>{{ $t('filter.tempos.medium_fast') }}</p>
                                </div>
                                </li>
                                <li :class="isActiveOption('medium', filters.tempo, ',')"><div v-on:click.stop="switchTempo('medium')" :data-hint="$t('filter.find_with_this_tempo')" class="hint-top-middle">
                                    <p>{{ $t('filter.tempos.medium') }}</p>
                                </div>
                                </li>
                                <li :class="isActiveOption('medium_slow', filters.tempo, ',')"><div v-on:click.stop="switchTempo('medium_slow')" :data-hint="$t('filter.find_with_this_tempo')" class="hint-top-middle">
                                    <p>{{ $t('filter.tempos.medium_slow') }}</p>
                                </div>
                                </li>
                                <li :class="isActiveOption('slow', filters.tempo, ',')"><div v-on:click.stop="switchTempo('slow')" :data-hint="$t('filter.find_with_this_tempo')" class="hint-top-middle">
                                    <p>{{ $t('filter.tempos.slow') }}</p>
                                </div>
                                </li>
                                <li :class="isActiveOption('very_slow', filters.tempo, ',')"><div v-on:click.stop="switchTempo('very_slow')" :data-hint="$t('filter.find_with_this_tempo')" class="hint-top-middle">
                                    <p>{{ $t('filter.tempos.very_slow') }}</p>
                                </div>
                                </li>

                            </ul>
                        </div>


                        <div class="track_list_filter_era_list">
                            <ul>
                                <li v-for="era in erasList" :class="isActiveOption(era, filters.era, ',')"><div v-on:click.stop="switchEra(era)" class="hint-top-middle">
                                    <i class="add_word hint-top-right" :data-hint="$t('filter.include')" v-on:click.stop="addEra(era)"></i>
                                    <p>{{ era }}</p>
                                </div>
                                </li>
                            </ul>
                        </div>
					</div>
				</div>
		</div>




		<div class="track_list_root">
            <div class="loader_bgnd">
                <div class="loader_bgnd_cont">
                    <div class="loader triangle">
                        <svg viewBox="0 0 86 80">
                            <polygon points="43 8 79 72 7 72"></polygon>
                        </svg>
                    </div>
                </div>
            </div>
			<div class="track_list_root_cont" style="height: calc(100vh - 145px);">


                <template v-if="searchIsEmpty">
                    <div class="container-fluid">
                        <h1>{{ $t('global.latest_alibi_albums') }}</h1>
                        <div class="main-page-album__wrapper">
                            <div class="row">
                                <div v-for="album in latestAlbums" class="col-md-3 col-lg-3 col-sm-6 col-xl-2">
                                    <div class="main-page-album">
                                        <a :href="'/alibi/album/'+album.slug">
                                            <div class="main-page-album_image"><img :src="getAlbumImage(album)"></div>
                                            <span>{{ album.tracks_count }} {{ $t('global.tracks_amount') }}</span>
                                            <strong><p>{{ album.name }}</p></strong>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>




				<AlibiTrackSearchForm
					ref="search_form"
					@setSearchText='setSearchText'></AlibiTrackSearchForm>

                <template v-if="searchIsEmpty === false">
                    <div class="container-fluid">
                        <template v-if="showClearListenedButton">
                            <button
                                style="margin-left: 8px; margin-top: -1px;"
                                v-on:click="removeListenedTracks()"
                                class="button-classic float-right">{{ $t('global.Clear_listened') }}</button>
                        </template>
                        <h1>{{ $t('global.tracks') }}</h1>
                        <AlibiTrackFilters
                            @removeEra='removeEra'
                            @removeTempo='removeTempo'></AlibiTrackFilters>

                        <div class="row">
                            <div class="col-md-4 search-results-info">
                                <span v-html="getImgSearchResultInfo()"></span>
                            </div>
                            <div class="col-md-8 search-order-container"><AlibiTrackOrderBlock @setOrder='setOrder'></AlibiTrackOrderBlock></div>
                        </div>
                    </div>
                    <el-pagination
                        layout="prev, pager, next"
                        :current-page.sync="page"
                        :page-size.sync="globalPageSize"
                        :total="alibi_tracksMeta.total"
                        class="pagination-padding">
                    </el-pagination>
                </template>

                <template v-if="searchIsEmpty === false">
                    <div class="container-fluid">

                        <div>
                            <div v-for="track in alibi_tracks" :key="track.id" v-bind:id="'track_block_' + track.id" v-bind:data-tid="track.id" class="track_common_block" :style="isPlackPlayed(track)">
                                <div class="track-block" >
                                    <div class="track-block_icon" v-bind:id="'track_play_btn_' + track.id" v-on:click="playTrack(track)" :style="getImgUrl(track)"></div>
                                    <div class="track-block_title">
                                        <strong>{{ track.title }} <span>{{ track.album_name }}</span></strong>
                                        <p>{{ track.description }}</p>
                                        <p class="lab_alb_trck">
                                            <a target="_blank" :href="'/alibi/album/'+track.album_slug" class="hint-top-middle" :data-hint="$t('global.open_album')">{{ $t('global.album') }}: {{ track.album_name }}</a>
                                            <span>/</span>
                                            <a target="_blank" :href="'/alibi/track/'+track.album_slug+'/'+track.id"  class="hint-top-middle" :data-hint="$t('global.open_track')">{{ $t('global.track') }}: {{ track.title }}</a>
                                            (<a target="_blank" :href="'/alibi/track/'+track.album_slug+'/'+track.id"  class="hint-top-middle" :data-hint="$t('global.open_track')">#{{ track.id }}</a>)
                                        </p>
                                        <p class="dates_secondary_info">
                                            <span><strong>{{ $t('track.added') }}:</strong> {{ track.release_date }}</span>
                                        </p>
                                    </div>
                                    <div class="track-block_bpm">{{ track.bpm }} BPM</div>
                                    <div class="track-block_time">{{ track.duration }}</div>
                                    <div class="track-block_ico hint-top-middle" data-hint="Add to Project">
                                        <template v-if="$auth.check()">
                                            <i v-on:click="addToProject(track, $event)" class="icon-btn-add-to-proj"></i>
                                        </template>&nbsp;
                                    </div>
                                    <div class="track-block_download">
                                        <template v-if="$auth.check()">
                                            <i v-on:click="getFileLink(track)" target="_blank" class="icon-btn-download"></i>
                                        </template>
                                        <template v-else>
                                            <a href="/login" style="opacity:1; position: relative" class="hint-top-right-s-small icon-btn-download" :data-hint="$t('global.login_to_download_a_track')"></a>
                                        </template>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>



                    <el-pagination
                        layout="prev, pager, next"
                        :current-page.sync="page"
                        :page-size.sync="globalPageSize"
                        :total="alibi_tracksMeta.total"
                        class="pagination-padding">
                    </el-pagination>
                </template>


				<el-dialog
						:title="formTitle"
						:visible.sync="formVisible"
						close-on-click-modal
						class="projects-dialog">
						<AlibiTrackForm :initialForm="formData" @addTrackToProjectAction='addTrackToProjectAction' @cancel="formVisible = false" />
					</el-dialog>

				<Footer></Footer>
			</div>
		</div>







	</section>
</template>

<script>
	import Vue from 'vue';
	import axios from 'axios';
    import {mapActions, mapGetters, mapMutations} from 'vuex'
    import {ALIBI_FETCH, ALIBI_OBTAIN} from "../store/types";
    import trackApi from '../api'

	import AlibiTrackForm from "./AlibiTrackForm";
	import AlibiTrackSearchForm from "./AlibiTrackSearchForm";
	import AlibiTrackFilters from "./AlibiTrackFilters";
	import AlibiTrackOrderBlock from "./AlibiTrackOrderBlock";
	import Footer from "../../../core/components/Footer";
    import ConfirmDialogue from "../../../core/components/ConfirmDialogue";

	import router from '../../../bootstrap/router';
	import VueCookie from 'vue-cookie';
	import VueSlider from 'vue-slider-component';

	Vue.use(VueCookie)

    export default {
        name: "AlibiList",
        components: {AlibiTrackForm,AlibiTrackSearchForm,AlibiTrackFilters,AlibiTrackOrderBlock,Footer,VueSlider,ConfirmDialogue},
        data: () => ({
            search_within: 'music_tracks',
			order: 'relevance',
            order_dir: 'relevance',



            // REFACTOR
            filters: {
                search: '',
				era: '',
				tempo: '',
            },
            page: 1,
			globalPageSize: 30,
			album_covers_server: config.albumCoversServer,
			music_server: config.musicServer,
            showClearListenedButton: false,
            trackForProject: null,
			keywords: {
				genres: null,
				moods: null,
				instruments: null,
				topic: null,
				performance: null,
				advanced: null
			},
			erasList: [
			    "1920",
                "1930s",
                "1940s",
                "1950s",
                "1960s",
                "1970s",
                "1980s",
                "Archaic",
                "Classical",
                "Historical",
                "Modern",
                "Traditional"
            ],
			lang: Vue.config.lang,
			activeFilter: null,

			bpm: [0,200],
			enableWatchers: false,
			formVisible: false,
			formData: null,
			formTitle: '',
            previous_track: null,
            searchIsEmpty: true,
            latestAlbums: []
        }),
        computed: {
            ...mapGetters(['alibi_tracks', 'alibi_tracksMeta', 'alibi_tracksLoading']),
        },
        created() {
            for (var i = 0; i<localStorage.length; i++) {
                if (localStorage.key(i).indexOf("track-alibi-") > -1) {
                    this.showClearListenedButton = true;
                }
            }

			if (!this.$auth.check()) {
				window.location = '/login';
			}
			else {
                this.cleanListenedTracks();
				this.filters.search = this.$route.query.search;
				this.filters.era = this.$route.query.era;
				this.filters.tempo = this.$route.query.tempo;

				this.search_within = this.$route.query.search_within;
				this.order = this.$route.query.order;
				this.order_dir = this.$route.query.order_dir;

				// REFACTOR
				this.page = parseInt(this.$route.query.page);
				this.globalPageSize = 30;

				let bpmMin = parseInt(this.$route.query.bpm_min);
				let bpmMax = parseInt(this.$route.query.bpm_max);
				if (isNaN(bpmMin)) {
					this.bpm[0] = 0;
				}
				else {
					this.bpm[0] = bpmMin;
				}
				if (isNaN(bpmMax)) {
					this.bpm[1] = 200;
				}
				else {
					this.bpm[1] = bpmMax;
				}
				this.fetchData();
				this.enableWatchers = true;
			}
        },

        beforeCreate() {
            this.keywords = Vue.config.keywordsCache;
        },
        mounted() {
            this.showFilter(this.$cookie.get('filter'), true);
            this.resetBpmLinkControl();
        },
        watch:{
            alibi_tracksLoading: function()
            {
                if (!this.alibi_tracksLoading) {
                    $('.loader_bgnd').fadeOut(300);
                }
            },

            page: function () {
                if (!this.enableWatchers) { return; }
                this.setURL();
            },
            lang: function() {

            },
            keywords: function() {
                if (!this.enableWatchers) { return; }
                if (this.keywords !== undefined && this.keywords.genres !== null) {
                    Vue.config.keywordsCache = this.keywords;
                }
            },
            bpm: function() {
                if (!this.enableWatchers) { return; }
                this.resetBpmLinkControl();
                this.setURL();
            }
        },








        // REFACTOR






        methods: {
            ...mapActions([ALIBI_FETCH]),
            ...mapMutations([ALIBI_OBTAIN]),

            fetchData() {
                if (!this.$auth.check()) {
                    return;
                }

                $('.loader_bgnd').fadeIn(300);

                let params = {
                    page: this.page,
                    search: this.filters.search,
                    order: this.order,
                    order_dir: this.order_dir,
                    search_within: this.search_within,
                    bpm_min: this.bpm[0],
                    bpm_max: this.bpm[1],
                    era: this.filters.era,
                    tempo: this.filters.tempo,
                };

                if (
                    this.filters.search ||
                    this.filters.era ||
                    this.filters.tempo
                ) {
                    this.searchIsEmpty = false;
                }
                else {
                    this.searchIsEmpty = true;
                    axios.get('/alibi/albums/latest',
                        {
                            params: { },
                            headers: {"Authorization" : 'Bearer '+window.localStorage.getItem('auth_token_default')},
                        }
                    )
                        .then(response => {
                            this.latestAlbums = response.data;
                        })
                        .catch(error => console.log(error))
                }

                this[ALIBI_FETCH](params)
            },
            getAlbumImage(album) {
                return album.image_url;
            },
            playTrack(trackObj) {
                initialPlayer();
                // Запоминаем, что трек проигран
                localStorage.setItem("track-alibi-"+trackObj.id, 3 * 24 * 60 * Math.floor(Date.now() / 1000));
                this.showClearListenedButton = true;
                // Сделаем сразу прозрачным на 0.7
                if (this.previous_track !== null) {
                    $("#track_block_"+this.previous_track.id).css('opacity', 0.7);
                }
                if ($("#jquery_jplayer_obj").data('play-track-id') == trackObj.id) {
                    if ($("#jquery_jplayer_obj").data('isPlaying')) {
                        $("#jquery_jplayer_obj").jPlayer("pause");
                    }
                    else {
                        $("#jquery_jplayer_obj").jPlayer("play");
                    }
                }
                else {
                    setPlayerDataALIBI(trackObj);
                    $("#jquery_jplayer_obj").jPlayer("setMedia", {
                        mp3: trackObj.stream_url,
                    }).jPlayer("play");
                    this.previous_track = trackObj;
                }
            },

            getFileLink(track) {
                axios.post('/track_download_alibi',
                    {

                            source_audio_id: track.source_audio_id,
                            track_id: track.id,
                            album_num: track.album_id,
                            album_slug: track.album_slug,
                            track_slug: track.slug,
                            album_name: track.album_name,
                            track_name: track.title,
                            track_url: track.stream_url
                        },
                    {
                        headers: {"Authorization" : 'Bearer '+window.localStorage.getItem('auth_token_default')},
                    }
                )
                    .then(response => {

                        if (response.data.block !== null && response.data.block == 1) {
                            alert("This option is disabled");
                            return;
                        }
                        if (response.data.block !== null && response.data.block == 2) {
                            this.showAlibiError();
                            return;
                        }

                        let fileLink = document.createElement('a');
                        fileLink.setAttribute('href', response.data.url);
                        fileLink.setAttribute('download', response.data.fname);
                        document.body.appendChild(fileLink);
                        fileLink.click();
                        document.body.removeChild(fileLink);
                    })
                    .catch(error => console.log(error))
            },

            showAlibiError(err) {
                const ok = this.$refs.confirmDialogue.show({
                    title: this.$t('global.license_for_the_ALIBI_catalog_title'),
                    message: this.$t('global.please_contact_us_alibi'),
                    okButton:'OK'
                });
            },
            isPlackPlayed(trackObj) {
                let isRecord = localStorage.getItem("track-alibi-"+trackObj.id);
                if (isRecord == null) {
                    return;
                }
                if (isRecord < Math.floor(Date.now() / 1000)) {
                    localStorage.removeItem("track-alibi-"+trackObj.id);
                    return;
                }
                return "opacity: 0.6";
            },




            // REFACTOR METGODS




			clearSearch() {
                this.filters.search = '';
                this.fetchData();
            },

			resetBpmLinkControl() {
				if (this.bpm[0] == 0 && this.bpm[1] == 200) {
					$("#reset_bpm_link").css('display', 'none');
				}
				else {
					$("#reset_bpm_link").css('display', 'inline-block');
				}
			},
			addKeyword(word) {
				this.filters.search = this.$refs.search_form.words;
				this.page = 1;
				if (this.filters.search == undefined || this.filters.search =="") {
					this.filters.search = word;
				}
				else {
					this.filters.search += ' '+word;
				}
				this.setURL();
				//this.fetchData();
			},
			excludeKeyword(word) {
				this.filters.search = this.$refs.search_form.words;
				this.page = 1;
				if (this.filters.search == undefined || this.filters.search =="") {
					this.filters.search = word;
				}
				else {
					this.filters.search += ' -'+word;
				}
				this.setURL();
				//this.fetchData();
			},
			getVisible(countVal) {
				if (countVal<1) {
					return "display:none";
				}
				else {
					return '';
				}
			},


			showFilter(filterBlock, force) {
				filterBlock = ""+filterBlock;
				if (filterBlock=='' || filterBlock == "undefined" || filterBlock == "null") {
					return false;
				}
				if (this.activeFilter == filterBlock && !force) {
					this.closeFilter();
					return;
				}

				this.activeFilter = filterBlock;
				this.$cookie.set('filter', filterBlock, 60 * 60 * 24 * 360);
				this.setActiveMenu(filterBlock);
				$('.track_list_filter_block_sub_cnt > div').css('display', 'none');
				if (Vue.config.keywordsCache == undefined || Vue.config.reloadKeywords) {
				   axios
						.get('keywords?lang='+Vue.config.lang)
						.then(response => (this.keywords = response.data ));
				}
				else {
					this.keywords = Vue.config.keywordsCache
				}


				$('.track_list_filter_block_sub_cnt .track_list_filter_'+filterBlock+'_list').css('display', 'block');

				/*
				 * SLIDERS
				 */
				if (filterBlock == "advanced") {
					///
				}


				$('.track_list_filter_block').css('width', '320px');
				$('.track_list_filter_block_sub').css('display', 'block');
			},
			closeFilter() {
				$('.track_list_filter_block_main li').removeClass('active');
				this.activeFilter = '';
				this.$cookie.set('filter', '', 60 * 60 * 24 * 360);
				$('.track_list_filter_block_sub_cnt > div').css('display', 'none');
				$('.track_list_filter_block').css('width', '115px');
				$('.track_list_filter_block_sub').css('display', 'none');

			},
			setActiveMenu(menuName) {
				menuName = ""+menuName;
				$('.track_list_filter_block_main li').removeClass('active');
				if (!menuName || menuName=="null" || menuName == "" || menuName == "undefined") {
					return false;
				}
				this.$refs['filters_main_'+menuName].classList.value = ['active'];
			},
			openLevel(e) {
				if ($(e.target.parentNode.parentNode).find("ul").hasClass('opened')) {
					$(e.target.parentNode.parentNode.parentNode).find("li > ul").removeClass('opened');
				}
				else {
					$(e.target.parentNode.parentNode.parentNode).find("li > ul").removeClass('opened');
					$(e.target.parentNode.parentNode).find("ul").addClass('opened');
				}
			},
			notEmptyTree(obj) {
				if (obj === undefined || obj.length == 0) {
					return false;
				}
				return true;
			},
			isActiveOption(val, collection, splitter) {
				if (collection === undefined) {
					return;
				}
				let collectionArray = collection.split(splitter);
				if (collectionArray.includes(""+val)) {
					return 'active-option';
				}
				else if (collectionArray.includes("-"+val)) {
					return 'active-option-excl';
				}
				else {
					return '';
				}
			},
			removeEra(era) {
				this.page = 1;
				this.filters.era = this.keywordManager(
					this.filters.era,
					era,
					0
				);
				this.setURL();
				//this.fetchData();
			},
			switchEra(era) {
				if (this.filters.era === undefined || this.filters.era === "") {
					this.addEra(era);
				}
				else {
					let collectionArray = this.filters.era.split(',');
					if (collectionArray.includes(""+era)) {
						this.removeEra(era);

					}
					else {
						this.addEra(era);

					}
				}
			},
			addEra(era) {
				this.page = 1;
				this.filters.era = this.keywordManager(
					this.filters.era,
					era,
					1
				);
				this.setURL();
				//this.fetchData();
			},
			switchTempo(tempo) {
				if (this.filters.tempo === undefined || this.filters.tempo === "") {
					this.filters.tempo = tempo;
				}
				else {
					if (this.filters.tempo == tempo) {
						this.filters.tempo = "";
					}
					else {
						this.filters.tempo = tempo;
					}
				}
				this.page = 1;
				this.setURL();
				//this.fetchData();
			},
			removeTempo() {
				this.page = 1;
				this.filters.tempo = "";
				this.setURL();
				//this.fetchData();
			},



			/*
			 * action - exclude (-1), include(1), remove(0)
			 */
			keywordManager(list,word,action) {
				var del = ',';
				if (list == undefined) {
					list = "";
				}
				var listArr = list.split(del);
				var newArr = [];
				var added = false;
				for (var i = 0; i<listArr.length; i++) {
					if (listArr[i] !== "") {
						if (listArr[i] == word || listArr[i] == '-'+word) {
							if (action == 1) {
								newArr.push(word);
								added = true;
							}
							else if (action == -1) {
								newArr.push('-'+word);
								added = true;
							}
						}
						else {
							newArr.push(listArr[i]);
						}
					}
				}
				if (!added) {
					if (action == 1) {
						newArr.push(word);
					}
					else if (action == -1) {
						newArr.push('-'+word);
					}
				}
				return newArr.join(del);
			},
			setSearchText (data) {
				this.filters.search = data.text;
				this.search_within = data.search_within;
				this.setURL();
			},
			setURL() {
				let queryVals = [];
				queryVals['search'] = this.filters.search;
                if (this.search_within) {
                    queryVals['search_within'] = this.search_within;
                }
                if (this.order) {
                    queryVals['order'] = this.order;
                }
                if (this.order_dir) {
                    queryVals['order_dir'] = this.order_dir;
                }
                if (this.page) {
                    queryVals['page'] = this.page;
                }


				if (this.bpm[0]) {
					queryVals['bpm_min'] = this.bpm[0];
				}
				if (this.bpm[1]) {
					queryVals['bpm_max'] = this.bpm[1];
				}

				if (this.filters.tempo) {
					queryVals['tempo'] = this.filters.tempo;
				}
				if (this.filters.era) {
					queryVals['era'] = this.filters.era;
				}



				router.push({ path: 'alibi', query: queryVals}).catch(()=>{});

			},
			getImgSearchResultInfo() {
				if (this.alibi_tracksMeta.from === undefined) {
					return '';
				}
				return this.$t('global.track') + ' <span>' + (this.alibi_tracksMeta.from + 1) + '</span> - <span>' + this.alibi_tracksMeta.to + '</span>, '+ this.$t('global.results')+': <span>' + this.alibi_tracksMeta.total + '</span>';
			},
			getImgUrl(track) {
				return "background-image:url('" + track.image_url + "')";
			},

			setOrder(data) {
				this.order = data.order;
				this.order_dir = data.order_dir;
				this.setURL();
			},
			showTracksVersions(l,a,t,id) {
				this.$refs['track_cnt_'+id][0].show(l,a,t, 1);
			},
			showTracksStems(l,a,t,id) {
				this.$refs['track_cnt_'+id][0].show(l,a,t, 2);
			},
            cleanListenedTracks() {
			    let currentTime = Date.now() / 1000;
			    let key = "";
                for (let i = 0; i < localStorage.length; i++) {
                    key = localStorage.key(i);
                    if (key.indexOf("track-") > -1) {
                        if (parseInt(localStorage.getItem(key)) < currentTime) {
                            localStorage.removeItem(key);
                        }
                    }
                }
            },
            removeListenedTracks() {
                let key = "";
                for (let i = 0; i < localStorage.length; i++) {
                    key = localStorage.key(i);
                    if (key.indexOf("rack-") > -1) {
                        localStorage.removeItem(key);
                    }
                }
                this.showClearListenedButton = false;
                $( "div.track_common_block" ).each(function() {
                    $( this ).css('opacity', 1);
                });
            },


			addTrackToProjectAction(data) {
			    let track = this.trackForProject;
				axios.post('/alibi_add_to_project',
						{
							project: data,
                            track: track
						},
                        {
                            headers: {"Authorization" : 'Bearer '+window.localStorage.getItem('auth_token_default')},
                        }
					)
					.then(response => {
					})
					.catch(error => console.log(error))

				this.formVisible = false
                this.fetchData()
			},
			addToProject(track,eventIcon) {
			    this.trackForProject = track;
				this.formTitle = 'Add to Project';
                this.formData = Object.assign({}, {
					track_id: track.id,
				});
                this.formVisible = true;
			},



        }
    }
</script>
