import * as types from './types'
import downloadApi from '../api'

export const actions = {
    async [types.DOWNLOAD_FETCH]({commit}, data = null) {
        commit(types.DOWNLOAD_SET_LOADING, true)
        const response = await downloadApi.all(data)
        commit(types.DOWNLOAD_OBTAIN, response.data.data)
        commit(types.DOWNLOAD_META, response.data.meta)
        commit(types.DOWNLOAD_SET_LOADING, false)
    },
}
