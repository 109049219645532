import * as types from './types'
import albumApi from '../api'

export const actions = {
    async [types.ALBUM_FETCH]({commit}, data = null) {
        commit(types.ALBUM_SET_LOADING, true)
        const response = await albumApi.all(data)
        commit(types.ALBUM_OBTAIN, response.data.data)
        commit(types.ALBUM_META, response.data.meta)
        commit(types.ALBUM_SET_LOADING, false)
    },
}
