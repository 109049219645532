<template>
    <div class="order-block">
		<em>{{ $t('sort.sort') }}</em>
		<ul>
			<li :class="showActiveSort('created')"><span>{{ $t('sort.added') }}</span>
				<ul>
					<li @click="setOrder('created', 'desc')">{{ $t('sort.high_to_low') }}</li>
					<li @click="setOrder('created', 'asc')">{{ $t('sort.low_to_high') }}</li>
				</ul>
			</li>
			<li :class="showActiveSort('published')"><span>{{ $t('sort.published') }}</span>
				<ul>
					<li @click="setOrder('published', 'desc')">{{ $t('sort.high_to_low') }}</li>
					<li @click="setOrder('published', 'asc')">{{ $t('sort.low_to_high') }}</li>
				</ul>
			</li>
			<li :class="showActiveSort('relevance')"><span>{{ $t('sort.relevance') }}</span>
				<ul>
					<li @click="setOrder('relevance', 'desc')">{{ $t('sort.high_to_low') }}</li>
					<li @click="setOrder('relevance', 'asc')">{{ $t('sort.low_to_high') }}</li>
				</ul>
			</li>
			
		</ul>
	</div>
</template>

<script>

    
    export default {
		data: () => ({
            sort: 'created',
			order: 'desc',
        }),
		mounted() {
		},
		methods: {
			showActiveSort(field) {
				if (this.$route.query.sort === "" || this.$route.query.sort === undefined) {
					var sort = this.sort;
					var order = this.order;
				}
				else {
					var sort = this.$route.query.sort;
					var order = this.$route.query.order;
				}
				if (sort == field) {
					return 'active_order_'+order;
				}
			},
			setOrder(sort, order) {
				this.$emit('setOrder', {
					sort: sort,
					order: order,
				})
			},
		}
    }
</script>

<style scoped type="scss">

</style>
