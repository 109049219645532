<template>
    <div class="search-field-root">
		<div class="search-field-container tracks-search-text">
			<form v-on:submit.prevent="setSearchText">
				<div class="search-field-container_input">
					<div :class="getCSSClass(word)" v-for="word in words">
						{{ word.word }}
						<button type="button" v-on:click.prevent="removeWord(word)"></button>
					</div>
						<input type="text" :placeholder="$t('global.find_the_track')" id="search-text" v-on:keyup="keymonitor">
						<button type="submit" class="search-submit-btn"></button>

				</div>
			</form>
		</div>
		
		<div class="clear-button">
			<button v-on:click="clearAll">{{ $t('global.clear_all') }}</button>
		</div>
		
	</div>
</template>

<script>

    import axios from 'axios';
    export default {
		data() {
			return {
				words: []
			};
		},
		mounted() {
			let wordsSet = [];
			if (this.$route.query.search !== undefined) {
				wordsSet = this.$route.query.search.split(" ");
			}
			for (let i = 0; i < wordsSet.length; i++) {
				if (wordsSet[i] == "") {
					continue;
				}
				if (wordsSet[i].substring(0,1) === "-") {
					this.words.push({
						word: wordsSet[i].substring(1).replace(/\_/g, ' '),
						exclude: 1
					});
				}
				else {
					this.words.push({
						word: wordsSet[i].replace(/\_/g, ' '),
						exclude: 0
					});
				}
			}
			
			$("#search-text").focus();
		},
		methods: {
			setSearchText: function(event) {
				let wordsSet = [];
				if (this.$route.query.search !== undefined) {
					wordsSet = this.$route.query.search.split(" ");
				}
				let newWordsSet = [];
				for (let i = 0; i < wordsSet.length; i++) {
					if (wordsSet[i]!=="") {
						newWordsSet.push(wordsSet[i]);
					}
				}
				newWordsSet.push($("#search-text").val());
				// console.log(wordsSet.join(" "));
				this.$emit('setSearchText', {
					text: newWordsSet.join(" "),
				})
			},
			clearAll:function() {
				this.$emit('setSearchText', {
					text: '',
				})
			},
			getCSSClass:function(word) {
				if (word.exclude) {
					return "search-word search-exclude";
				}
				else {
					return "search-word";
				}
			},
			keymonitor:function(event) {
				if (event.key === "Backspace" && $("#search-text").val() === "") {
					this.removeWord(this.words[(this.words.length - 1)]);
				}
				if (event.key === "Enter" && $("#search-text").val() !== "") {
					this.setSearchText();
				}
			},
			removeWord:function(word) {
				
				let removeWord = word.word;
				if (word.exclude) {
					removeWord = "-"+removeWord;
				}
				let wordsSet = this.$route.query.search.split(" ");
				
				let newWordsSet = [];
				for (let i = 0; i < wordsSet.length; i++) {
					if (removeWord !== wordsSet[i].replace("_"," ")) {
						newWordsSet.push(wordsSet[i]);
					}
				}
				this.$emit('setSearchText', {
					text: newWordsSet.join(" "),
				})
				
				/*
				this.words = [];
				for (let i = 0; i < wordsSet.length; i++) {
					if (wordsSet[i].substring(0,1) === "-") {
						if (word !== wordsSet[i].substring(1)) {
							this.words.push({
								word: wordsSet[i].substring(1),
								exclude: 1
							});
						}
					}
					else {
						if (word !== wordsSet[i]) {
							this.words.push({
								word: wordsSet[i],
								exclude: 0
							});
						}
					}
				} 
				*/
			}
			
			
			
		}
    }
</script>

<style scoped type="scss">

</style>
