import SFXList from "./components/SFXList";
import SfxView from "./components/SfxView";

export const routes = [
    {
        path: '/sfx',
        name: 'SFX',
        component: SFXList,
    },
    {
        path: '/sfx/:id',
        name: 'Show Sfx',
        component: SfxView,
        hidden: true
    }
]
