import Index        from "./components/Index";
import NotFound     from "./components/NotFound";
import Welcome      from "./components/Welcome";
import auth         from '../modules/auth/routes_auth';


// Load modules routes dynamically.
const requireContext = require.context('../modules', true, /routes\.js$/)

const modules = requireContext.keys()
    .map(file =>
        [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)]
    )

let moduleRoutes = [];

for(let i in modules) {
    moduleRoutes = moduleRoutes.concat(modules[i][1].routes)
}
let moduleRoutes2 = [];

export const routes = [
    {
        path: '/',
		redirect: '/tracks',
        component: Welcome,
		name: 'index',
        meta: {auth: false},
        children: [
			// Пути для авторизированных пользователей
            ...moduleRoutes2,
        ]
    },
    {
        path: '/',
        component: Welcome,
        children: [
			// Обычные пути, без авторизации
            ...moduleRoutes,
			// Пути авторизации
            ...auth,
            {
                path: '*',
                component: NotFound,
                name: 'not_found'
            }
        ]
    },
];

