import * as types from './types'
import {actions} from './actions'

export const store = {
    state: {
        sfxes: [],
        sfxesMeta: [],
		sfx_sample: [],
        sfxesLoading: true,
    },
    getters: {
        sfxes: state => state.sfxes,
		sfx_sample: state => state.sfx_sample,
        sfxesMeta: state => state.sfxesMeta,
        sfxesLoading: state => state.sfxesLoading,
    },
    mutations: {
        [types.SFX_OBTAIN](state, sfxes) {
            state.sfxes = sfxes
        },
		[types.SFX_SAMPLE](state, sfx_sample) {
            state.sfx_sample = sfx_sample
        },
        [types.SFX_CLEAR](state) {
            state.sfxes = []
        },
        [types.SFX_SET_LOADING](state, loading) {
            state.sfxesLoading = loading
        },
        [types.SFX_META](state, meta) {
            state.sfxesMeta = meta
        },
    },
    actions
}
